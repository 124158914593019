import React, { useState } from 'react';
import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img1 from "../../assets/images/otherIndus1.jpg";
import img2 from "../../assets/images/otherIndus2.jpg";
import img3 from "../../assets/images/otherIndus3.jpg";
import img4 from "../../assets/images/otherIndus4.jpg";

import { useEffect} from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;

  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #CE9333;
 
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;




export function ContentCardIndus3(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Security Seals & Fasteners
</CardTitle>
            <CardHr />
            <CardText>
            Security is paramount in logistics. PLASTICUM manufactures security seals and fasteners used in securing cargo during transportation. These components are designed for tamper-evidence, ensuring the integrity and safety of transported goods, especially for high-value or sensitive shipments.
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img1} />
  <BlueBackground className="backgroundColor-969BAE" />

</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img2} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Mechatronic Applications</CardTitle>
            <CardHr />
            <CardText>
            We manufacture injection-molded parts for mechatronic applications, including robotics, automation systems, and advanced industrial machinery. Our components help in the creation of robotic arms, automated assembly line components, and precision actuators, enabling seamless integration of mechanical and electronic systems.

            </CardText>
      
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Powering the Future of Electronics
</CardTitle>
            <CardHr />
            <CardText>
            The electric industry demands components that can withstand rigorous use and ensure safety. PLASTICUM is your partner for plastic injection molding solutions that meet the challenges of the electric sector. Whether it's connectors, insulators, or enclosures for electrical equipment, we deliver high-quality, durable solutions.

            </CardText>
     
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
        <CardImgR src={img3} /> 
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 
<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img4} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Charging Infrastructure</CardTitle>
            <CardHr />
            <CardText>
            The charging infrastructure is a crucial aspect of the electric vehicle ecosystem. We manufacture components for charging stations and connectors that facilitate safe and efficient charging. Our injection-molded parts ensure the durability, insulation, and connectivity required for EV charging solutions.
            </CardText>
      
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
   
    </>
  );
}

import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img1 from "../../assets/images/Co-design & Product Development/TABLE FEUILLES+PRODUIT.jpg";
import img2 from "../../assets/images/Screenshot 2023-09-19 at 09.15.39.png";
import img3 from "../../assets/images/Co-design & Product Development/3D OPTIQUE-1.jpg";
import img4 from "../../assets/images/Co-design & Product Development/MECANIQUE-4.jpg";
import img5 from "../../assets/images/Footer/footer6.jpg";
import img6 from "../../assets/images/Co-design & Product Development/456A8812-2.jpg";


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #080A0F;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #5DCA3C;
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #627D92;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;

const BlueBackgroundL4 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #323957;
`;
const BlueBackground5 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color:  #080A0F;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackgroundL6 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color:  #080A0F;
`;
const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;
export function ContentCardT1 (props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Collaborative Design Workshops</CardTitle>
            <CardHr />
            <CardText>
            Our engineers work closely with your team to brainstorm ideas, refine concepts, and ensure that every detail is considered. We encourage open dialogue, brainstorming, and the exploration of multiple design concepts to find the best solutions. Your input is central to the workshop process.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img1} />
  <BlueBackground className="backgroundColor-969BAE" />

</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 



     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img2}/>
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Design for Manufacturability (DFM)</CardTitle>
            <CardHr />
            <CardText>
            We conduct thorough DFM assessments to identify potential issues and streamline the manufacturing process. By addressing these challenges early, we streamline the manufacturing process, resulting in faster turnaround times With DFM, we can quickly iterate and refine prototypes, reducing development time and allowing for faster product launches.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>From Concepts to Prototypes</CardTitle>
            <CardHr />
            <CardText>
            Prototypes are the bridge between concept and production. We create physical prototypes that allow you to see, touch, and test your design. This hands-on approach is invaluable for refining and validating your concept.
            </CardText>
          
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
          <CardImgR src={img3}/>
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 

<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2>
        <CardImg src={img4} />
        <BlueBackgroundL4/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Iterative Improvement</CardTitle>
            <CardHr />
            <CardText>
            Prototyping often leads to insights and improvements. We iterate and refine the design based on your feedback, ensuring that the final product aligns perfectly with your vision and objectives.
            
           </CardText>
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Material Selection Guidance</CardTitle>
            <CardHr />
            <CardText>
            Choosing the right material is critical. We help you choose the most suitable materials for your project, considering factors such as strength, durability, cost, and environmental impact. Our commitment to sustainability means that we prioritize eco-friendly materials whenever possible.
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img5}/>
  <BlueBackground5 />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2>
        <CardImg src={img6} />
        <BlueBackgroundL6/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Turn your vision into a manufacturable reality</CardTitle>
            <CardHr />
            <CardText>Throughout the entire co-design and development journey, you can expect open communication, transparency, and a relentless pursuit of excellence. We're not just in the business of making products; we're in the business of bringing your visions to life.  
           </CardText>
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>


    </>
  );
}

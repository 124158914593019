export const MainTitle = ({ titleText ='',text='' }) => {
  return (
    <div className='mt-16 '>
    <div className=' flex flex-col items-center justify-center text-center pb-8'>
      <p className='text-[40px] font-bold text-main-blue '>{titleText}</p>
      <hr className=' w-36 h-1  mt-4 bg-secondary-blue border-0 rounded' />
      <p className='pt-8 pb-8'>{text}</p>
    </div>
    </div>
  );
};
export const BigTitle = ({ titleText = '', text = '' }) => {
  return (
    <div className='flex flex-col justify-start pt-32 lg:w-[40rem] '>
      <p className='lg:text-6xl sm:text-4xl font-bold text-white text-4xl sm:text-3xl sm:pl-22'>
        {titleText}
      </p>
      <hr className='w-[30rem] h-2 mt-8 bg-white border-0 rounded' />
      <p className='pt-8 pb-8 text-gray-100'>{text}</p>
    </div>
  );
}

export const BigTitleBlue = ({ titleText = '', text = '' }) => {
  return (
    <div className='flex flex-col justify-start pt-32 lg:w-[40rem] ml-10'>
      <p className='lg:text-6xl sm:text-4xl font-bold text-main-blue text-3xl sm:text-3xl'>{titleText}</p>
      <hr className='w-[30rem] h-2 mt-8 bg-secondary-blue border-0 rounded' />
      <p className='pt-8 pb-8 pr-[10rem] text-black text-justify'>{text}</p>
    </div>
  );
}

export const SecondTitle =({titleText =''}) => {
  return(
    <div className='  flex flex-col items-center justify-center text-center pb-8'>
      <p className='text-[40px] font-bold text-main-blue'>{titleText}</p>
    </div>
  )
}

export const SecondLittleTitle = ({ titleText = '', className = '' }) => {
  return (
    <div className={`flex flex-col pb-8 ${className}`}>
      <p className='text-[35px] font-bold text-main-blue'>{titleText}</p>
    </div>
  )
}

export const LittleTitle = ({ titleText = '', text = '', lineColor = 'bg-secondary-blue' }) => {
  return (
    <div className='flex flex-col justify-start sm:text-[15px]'>
      <p className='text-[25px]  font-bold text-main-blue justify-start  text-justify '>{titleText}</p>
      <hr className={`w-20 h-1 mt-1 ${lineColor} border-0 rounded`} />
      <p className='pt-2 pb-2 pr-10 text-[15px] lg:w-[500px]'>{text}</p>
    </div>
  )
}

export const SecondaryTitle =({titleText='',text=''}) => {
  return(
  <div className="flex flex-col  ">
    <p className="text-[36px] font-bold text-main-blue justify-start text-justify">{titleText}</p>
    <hr className=" w-32 h-1.5 mt-[1rem] mb-[2rem] bg-main-blue border-0 rounded" />
    <p className="text-[20px] text-neutral-600 dark:text-neutral-200 text-justify mb-[1rem]">{text}</p>

  </div>
)}
import CompanyMainCard from "../../components/Cards/companyMainCard";
import { Footer } from "../../components/footer";
import ValuesCard from "../../components/Cards/valuesCard";
import Directeur from "../../components/Cards/directeurCard";
import Company1 from "../../components/Cards/company1";
import Company2 from "../../components/Cards/company2";
import Company3 from "../../components/Cards/company3";
import {ContentCardCompany} from "../../components/Cards/contentCardCompany";
import { MainTitle, SecondLittleTitle, SecondTitle } from "../../components/Typography/titles";
import { ContactNavBar } from "../../components/contactNavBar";
import Nav from "../../components/nav";
import React ,{useEffect,useState} from 'react'
import footerImage from "../../assets/images/Footer/footer6.jpg";

import BackToTopButton from "../../components/Buttons/backToTop";
const Company =() =>{
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
return(
  <div className="">
    <ContactNavBar />
      <Nav />
      <CompanyMainCard />
    <ValuesCard />
    <Directeur/>
    <div className="top-[50px]" >
    
    
   <ContentCardCompany/>
    <BackToTopButton show={showBackToTop} />
      <Footer backgroundImage={`url(${footerImage})`}/>

    </div>
    </div>
)

}
export default Company;
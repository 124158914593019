
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { MainTitle } from '../Typography/titles';
import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';


interface PopupProps {
  imageUrl: string;
  onClose: () => void;
  productDetails: string;
}

const PopupForm: React.FC<PopupProps> = ({ imageUrl, onClose, productDetails }) => {

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (formRef.current) {
      emailjs
        .sendForm('service_4qtb1kk', 'template_lbkyfwq', formRef.current, 'AXPNpvQy0PtKdBBa_')
        .then(
          (result) => {
            console.log(result.text);
            console.log('message sent');
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
        <div className="w-[60rem] h-[31rem] bg-main-blue p-8  rounded-lg shadow-md mt-[4rem]">
          <form ref={formRef} onSubmit={sendEmail}>
            <div className="grid grid-cols-2 gap-6">

              <label className="block">
                <span className="text-white">First Name *</span>
                <input
                  type="text"
                  name="firstName"
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  required
                  style={{ height: '2.8rem' }}
                />
              </label>

              <label className="block">
                <span className="text-white">Last Name *</span>
                <input
                  type="text"
                  name="lastName"
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  required
                  style={{ height: '2.8rem' }}
                />
              </label>

              <label className="block">
                <span className="text-white">Email *</span>
                <input
                  type="email"
                  name="email"
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  required
                  style={{ height: '2.8rem' }}
                />
              </label>

              <label className="block">
                <span className="text-white">Phone Number *</span>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <select
                    name="countryCode"
                    className="absolute inset-y-0 left-0 px-2 py-2 bg-gray-100 border-transparent rounded-md  focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  >
                    <option value="+26">+216 (TN)</option>
                    <option value="+44">+44 (UK)</option>
                  </select>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="  pl-36 p-2  pr-10 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                    required
                    style={{ height: '2.8rem' }}
                  />
                </div>
              </label>

              <label className="block">
                <span className="text-white">Position in which you want to apply *</span>
                <input
                  type="text"
                  name="postion"
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  required
                  style={{ height: '2.8rem' }}
                />
              </label>



              <label className="block">
                <span className="text-white">Attach your Curriculum Vitae *</span>
                <input
                  type="file"
                  name="file"
                  style={{ height: '2.8rem' }}
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                />
              </label>

              <label className="block ">
                <span className="text-white">Message</span>
                <textarea
                  name="message"
                  className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                  rows={4}
                  required
                />
              </label>
              <div className=' mt-24  place-content-end flex'>
                <button className='bg-secondary-blue text-white font-bold w-[12rem] h-[3rem] rounded-lg ' type='submit'>Apply</button>
              </div>
            </div>
          </form>

          <button
            onClick={onClose}
            className="absolute text-white bottom-[34.5rem] right-[18rem]  w-[2rem]  h-[2rem] "
          >
            <FontAwesomeIcon icon={faX} />
          </button>
        </div>
      </div>
    </>
  )
}

type PositionData = {
  title: string;
  type: string;
  pays: string;
  overview: string;
  responsibilities: string[];
  requiredSkills: string[];
  additionalRequirements: string[];
  lastDatetoPost: string;
  _id?: string;
  description?: string;
  isActive?: boolean;
  __v?: number;
  isAdmin?: boolean;
};

const OpenPositionsCard = () => {
  const [openPositions, setOpenPositions] = useState<boolean[]>([]);
  const [showApplyForm, setShowApplyForm] = useState(false);
  const [selectedPositionIndex, setSelectedPositionIndex] = useState(null);
  const [positionsData, setPositionsData] = useState<PositionData[]>(
    [

      {
        "_id": "64fb41a983795162edcd7af4",
        "title": "Project Manager",
        "type": "Full-time",
        "pays": "Tunisia",
        "overview": "Reporting to the Development and Industrialization Department, you will manage industrial projects in compliance with the quality/cost/time triptych. You will be responsible for the technical, administrative and budgetary management of projects, from the bid phase through design to completion. You will be PLASTICUM's representative and contact with the customer, and the customer's representative with PLASTICUM.",
        "description": "",
        "responsibilities": [
          "Contribute to the company's sales policy with the Sales Department.",
          "Ensure that sales & quality targets are met.",
          "Ensure that orders comply with negotiated quotations.",
          "Monitor customer files and control sales and logistics administration.",
          "Participate in meetings to launch new customer projects and define objectives.",
          "Determine the project's development phases and all the steps involved.",
          "Plan the human, technical and financial resources required, and discuss these points with the relevant departments.",
          "Monitor the project from design to delivery.",
          "Act as an intermediary between the Methods, Quality, Production, Logistics, Purchasing and Sales functions.",
          "Present the customer with the first parts (mock-up, Proto, etc.) and process any necessary changes expressed by the customer.",
          "Manage the project budget, arbitrate any choices to be made according to risk and results.",
          "Organize and/or participate in reviews (Plan, End of Study, IOD, EI, Transfer to Production) and tests.",
          "Ensure that the Initial Samples are sent on time."
        ],
        "requiredSkills": [
          "Trained as a mechanical, electromechanical or industrial engineer, you have at least 2 years' experience in a similar position.",
          "Fluent written and spoken French and English.",
          "Knowledge of FMEA and SPC design tools and techniques.",
          "You are a good communicator, organized and rigorous.",
          "You have good interpersonal skills and know how to work as part of a team."
        ],
        "additionalRequirements": [],
        "lastDatetoPost": "February 2024",
        "__v": 0,
        "isActive": true
      },
      {
        "_id": "64fb41a983795162edcd7af4",
        "title": "Computer and Information Systems Manager",
        "type": "Full-time",
        "pays": "Tunisia",
        "overview": "Reporting to General Management, you will be responsible for installing and maintaining IT equipment and software.",
        "description": "",
        "responsibilities": [
          "Plan, organize, control and evaluate the various activities related to computer systems.",
          "Ensure the reliability and security of each department's data.",
          "Create and maintain effective dashboards using reporting tools such as Power BI.",
          "Manage the structure of shared documents to facilitate internal collaboration.",
          "Actively participate in the company's process digitization project.",
          "Monitor the interfacing of application solutions with ERP.",
          "Implement and maintain IT systems for information management.",
          "Work with operational teams to understand needs and propose appropriate technological solutions.",
          "Train and support end-users in the use of the solution.",
          "Stay on top of the latest trends in information systems and reporting technologies."
        ],
        "requiredSkills": [
          "You are a young graduate engineer with a background in applied computing and at least 2 years' experience in a professional, industrial environment.",
          "Experience with Sage x3 ERP is a plus.",
          "Knowledge of reporting software, especially Power BI.",
          "Knowledge of other tools is a plus.",
          "Ability to work in a team and under pressure.",
          "Versatile, meticulous and well-organized.",
          "Ability to handle emergencies.",
          "Excellent communication skills and the ability to explain technical concepts clearly."
        ],
        "additionalRequirements": [],
        "lastDatetoPost": "February 2024",
        "__v": 0,
        "isActive": true
      },
      {
        "_id": "64fb41a983795162edcd7af4",
        "title": "Suppliers & Customers Accountant",
        "type": "Full-time",
        "pays": "Tunisia",
        "overview": "Reporting to the Finance Department, you will be responsible for the general accounting of customers and suppliers. You will be responsible for recording the company's investments, income and expenditure, with the end goal of preparing the annual accounts as well as tax and financial declarations.",
        "description": "",
        "responsibilities": [
          "General accounting.",
          "Systematically update financial documents (journals and accounting books).",
          "Maintain regular bank reconciliations.",
          "Prepare year-end work: regularization, analysis, and adjustment of accounts.",
          "Prepare annual financial statements, and handle tax returns.",
          "Prepare payment documents.",
          "Process local supplier payments.",
          "Invoicing for local customers."
        ],
        "requiredSkills": [
          "Bac+3/5 specializing in accounting or Accounting Control and Audit (CCA).",
          "Eligible for CIVP (H) contract.",
          "Fluent in written and spoken French and English.",
          "Autonomous, rigorous, with excellent interpersonal, listening and adaptability skills."
        ],
        "additionalRequirements": [],
        "lastDatetoPost": "February 2024",
        "__v": 0,
        "isActive": true
      },
      {
        "_id": "64fb41a983795162edcd7af4",
        "title": "Mechanical Technician",
        "type": "Full-time",
        "pays": "Tunisia",
        "overview": "Attached to the Mechanical Workshop, your role is to ensure the maintenance and preventive care of molds and tooling.",
        "description": "",
        "responsibilities": [
          "Regularly control and monitor tooling.",
          "Plan and organize maintenance activities and operations.",
          "Propose solutions to optimize the safety and performance of the equipment.",
          "Adhere to safety rules."
        ],
        "requiredSkills": [
          "Holder of a BTS in Mold Manufacturing and Maintenance.",
          "Eligible for a CIVP contract.",
          "Good interpersonal skills and ability to work in a team."
        ],
        "additionalRequirements": [],
        "lastDatetoPost": "February 2024",
        "__v": 0,
        "isActive": true
      },
      
      

      // ... other job listings if needed ...
    ])
    


  const imageUrl = 'https://tn.jumia.is/unsafe/fit-in/300x300/filters:fill(white)/product/50/2455/1.jpg?6307';
  const productDetails = 'Product Details Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

  useEffect(() => {
    const fetchPositionsData = async () => {
      try {
        const response = await fetch('https://plasticum.tn/api/jobs');
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des postes ouverts');
        }
        const data = await response.json();
        setPositionsData(data);
      } catch (error) {
        console.error('Erreur lors du chargement des postes ouverts :', error);
      }
    };

    fetchPositionsData();
  }, []);

  const togglePosition = (index: number) => {
    const updatedPositions = [...openPositions];
    updatedPositions[index] = !updatedPositions[index];
    setOpenPositions(updatedPositions);
  };

  const openFormPopup = () => {
    setShowApplyForm(true);
  }

  const closeFormPopup = () => {
    setShowApplyForm(false);
  }

  return (
    <div className="mx-4 md:mx-16 lg:mx-24 xl:mx-44 my-8 md:my-16 lg:my-28">
      <MainTitle titleText='Open Positions' text="Unlock your potential and join us" />
      {positionsData.map((position, index) => (
        <div key={index}>
          <div className='border-b-2 grid grid-cols-1 md:grid-cols-2 h-auto md:h-[3rem] my-4'>
          <h2 onClick={() => togglePosition(index)} className='text-xl md:text-2xl text-main-blue font-bold' style={{ cursor: 'pointer' }}>{position.title}</h2>
            <button onClick={() => togglePosition(index)} className='place-content-end flex'>
              {openPositions[index] ? '-' : '+'}
            </button>
          </div>
          {openPositions[index] && (
            <div>
              <div className='grid grid-cols-1 md:grid-cols-2'>
                <h1 className='text-lg md:text-xl font-bold text-main-blue'>{position.type}</h1>
                <h1 className='text-lg md:text-xl font-bold text-main-blue place-content-end flex'>{position.pays}</h1>
              </div>

              <div className='my-6 md:my-10'>
                <h1 className='pb-4 md:pb-6 text-black font-bold text-main-blue text-lg md:text-xl'>Job Overview</h1>
                <p>{position.overview}</p>
              </div>

              <div className='my-6 md:my-10'>
                <h1 className='pb-4 md:pb-6 text-black font-bold text-main-blue text-lg md:text-xl'>Responsibilities</h1>
                <ul style={{ listStyleType: 'circle' }} className='pl-4 md:pl-12'>
                  {position.responsibilities.map((responsibility, idx) => (
                    <li key={idx}>{responsibility}</li>
                  ))}
                </ul>
              </div>

              <div className='my-6 md:my-10'>
                <h1 className='pb-4 md:pb-6 text-black font-bold  text-main-blue text-lg md:text-xl'>Required Skills</h1>
                <ul style={{ listStyleType: 'circle' }} className='pl-4 md:pl-12'>
                  {position.requiredSkills.map((skill, idx) => (
                    <li key={idx}>{skill}</li>
                  ))}
                </ul>
              </div>
              <p className='pb-4 md:pb-6 text-black font-bold  text-main-blue text-lg md:text-xl'>Submission Deadline: </p>
              <b>{position.lastDatetoPost}</b>
              <br></br>
              <br></br>
              <p className='text-black font-bold '>
                Please submit your application to  <a href="mailto:recrutement@plasticum.tn" style={{ color: 'blue', textDecoration: 'underline' }}>
                    recrutement@plasticum.tn
                </a>
              </p>
              <br></br>
              <br></br>
            </div>
          )}
        </div>
      ))}
      {showApplyForm && (
        <PopupForm imageUrl={imageUrl} onClose={closeFormPopup} productDetails={productDetails} />
      )}
    </div>
  );
}

export default OpenPositionsCard;

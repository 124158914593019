import React, { useState } from 'react';
import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';

import img1 from "../../assets/images/Automotive/Frame 195.jpg";
import img2 from "../../assets/images/Automotive/456A8741-2.jpg";
import img3 from "../../assets/images/Automotive/SCANIA.jpg";
import img4 from "../../assets/images/Automotive/456A8782-2.jpg";
import img5 from "../../assets/images/Automotive/456A8794-2.jpg";
import img6 from "../../assets/images/Automotive/456A8842-2.jpg";
import { useEffect} from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #1B3F95;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #01479B;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #969BAE;
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;




export function ContentCardIndus(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>IATF 16949
</CardTitle>
            <CardHr />
            <CardText>
            At PLASTICUM, our commitment to quality and excellence is underlined by our certification under the International Automotive Task Force (IATF) standards. We are proud to be IATF certified, a testament to our dedication to meeting the highest benchmarks in the automotive industry. This certification reaffirms our compliance with stringent quality management systems, ensuring that every aspect of our operations, from design to manufacturing, aligns with the industry's best practices.
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img1} />
  <BlueBackground className="backgroundColor-969BAE" />

</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img2} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Interior Components</CardTitle>
            <CardHr />
            <CardText>
            In the interior of a vehicle, aesthetics, comfort, and functionality are paramount. We manufacture a wide range of injection-molded interior components, including dashboards, door panels, seat components, and trims. These components are designed to enhance the driver and passenger experience while ensuring durability and attractive aesthetics.
            </CardText>
      
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Exterior Components</CardTitle>
            <CardHr />
            <CardText>
            Exterior components demand precision and resilience to withstand various environmental conditions. Our injection-molded exterior parts, such as bumpers, grilles, body panels, and mirror housings, are crafted to provide not only structural integrity but also aesthetic appeal. We strive to contribute to the overall design and aerodynamics of the vehicle.
            </CardText>
     
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
        <CardImgR src={img3} /> 
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 
<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img4} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Engine & Under-the-Hood Parts</CardTitle>
            <CardHr />
            <CardText>
            In the engine and under-the-hood areas, performance, heat resistance, and durability are critical. We produce a range of components such as air intake manifolds, engine covers, and cooling system parts using advanced materials and techniques. These components are designed to withstand high temperatures and maintain optimal functionality.
            </CardText>
      
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Electrical & Electronic Systems</CardTitle>
            <CardHr />
            <CardText>
            Modern vehicles incorporate a multitude of electrical and electronic systems. Our injection-molded parts include connectors, brackets, and enclosures for these systems. We ensure precise manufacturing to meet the industry's stringent electrical and safety standards.

            </CardText>
     
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
        <CardImgR src={img5} /> 
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 
<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img6} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Safety & Functional Parts</CardTitle>
            <CardHr />
            <CardText>
            Safety is a paramount concern in the automotive industry. We manufacture critical safety components such as airbag housings, seat belt parts, and sensor enclosures, ensuring they meet rigorous safety standards and contribute to the overall safety of the vehicle.

            </CardText>
      
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
    </>
  );
}

import React from 'react';
import { BigTitle } from '../Typography/titles';
import { GetRfiButton } from '../Buttons';
import VerticalSlider from './verticalSlider';
import { useState,useEffect } from 'react';
import logoP from "../../assets/images/logoP.png"
import { To, useNavigate } from 'react-router-dom';
const vid = require('../../assets/videos/vid.mp4');
const useScrollToTopNavigate = () => {
  const navigate = useNavigate();

  return (path: To) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
};
const MainHomeCard = () => {
  
  
  const [divsVisible, setDivsVisible] = useState(true);
  const navigateToTop = useScrollToTopNavigate(); // Using the custom hook

  const handleButtonClick = () => {
    setDivsVisible(false);
    navigateToTop('/contact-us'); // Navigate to 'contact-us' and scroll to top
  };




  return (
    <div className='flex flex-col lg:flex-row relative'>
<div className='background-video-container video-container relative lg:rounded-tr-[30rem] lg:rounded-br-[30rem] w-full lg:w-2/3'>
  <video  preload="auto"  className='background-video' autoPlay loop muted>
    <source src={vid} type="video/mp4"  />
  </video>
  <div className='p-6 lg:p-24 space-y-6 lg:space-y-18 pl-24' style={{ zIndex: 2, marginTop: '-90px'}}>
    <BigTitle titleText="Solutions' provider for plasturgy" text='PLASTICUM is on a mission to redefine the landscape of plastic injection molding by seamlessly blending innovation, sustainability, and certified excellence, crafting precision solutions that shape industries and contribute to a greener, more resilient future '/>
    <GetRfiButton onClick={handleButtonClick} />
  </div>
</div>

 
      <div className='mb-0 vertical'>
    <VerticalSlider />
</div>

  

    



    </div>
    
  );
};

export default MainHomeCard;
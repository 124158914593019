import React from 'react';
import { MainTitle, SecondLittleTitle } from "../Typography/titles";
import { Reveal } from '../Reveal';
import v1 from "../../assets/images/v1.png";
import v2 from "../../assets/images/v2.png";
import v3 from "../../assets/images/v3.png";

const ValuesCard = () => {
  // Define the new card data with titles and images
  const cardData = [
    {
      image: v1,
      title: "Reliability",
      text: "Ensuring the highest standards of quality and security in our products.",
    },
    {
      image: v2,
      title: "Innovation",
      text: "Continually advancing our technology and processes for cutting-edge solutions.",
    },
    {
      image: v3,
      title: "Rigor",
      text: "Offering comprehensive services from design to production for complete client satisfaction.",
    },
  ];

  return (
    <Reveal>
      <div className="">
        <MainTitle titleText="Our values" text="What PLASTICUM stands for" />

        <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-8 values-grid h-auto md:h-[200px] mb-4">
          {cardData.map((item, index) => (
            <div key={index} className="flex flex-col justify-center items-center" >
              <img src={item.image} alt={`Value ${index + 1}`} className="w-41 h-40 mx-auto" />
              <SecondLittleTitle titleText={item.title} />
            </div>
          ))}
        </div>
      </div>
    </Reveal>
  );
}

export default ValuesCard;

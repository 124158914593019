import React ,{useEffect,useState} from 'react'
import CaseStudiesCard from "../../components/Cards/caseStudiesCard";
import { ContentCardUni } from "../../components/Cards/contentCardUni";
import { ContentCardTwo } from "../../components/Cards/contentCardtwo";
import { ContentCardT1 } from "../../components/Cards/contentCardT1";
import { ContentCardT2 } from "../../components/Cards/contentCardT2";
import { ContentCardT3 } from "../../components/Cards/contentCardT3";
import { ContentCardT4 } from "../../components/Cards/contentCardT4";
import { ContentCardT5 } from "../../components/Cards/contentCardT5";
import Nav from "../../components/nav";
import NewsCard from "../../components/Cards/newsCard";
import FirstUniversalCard from "../../components/Cards/firstUniversalCard";
import ZoomCard from "../../components/Cards/zoomCard";
import { MainTitle } from "../../components/Typography/titles";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import BackToTopButton from '../../components/Buttons/backToTop';
import SecondUniversalCardT1 from '../../components/Cards/SecondUniversalCardT1';
import SecondUniversalCardT2 from '../../components/Cards/SecondUniversalCardT2';
import SecondUniversalCardT3 from '../../components/Cards/SecondUniversalCardT3';
import SecondUniversalCardT4 from '../../components/Cards/SecondUniversalCardT4';
import SecondUniversalCardT5 from '../../components/Cards/SecondUniversalCardT5';
import styled, { css } from 'styled-components';
import i8 from "../../assets/images/P/i8.png"
import i9 from "../../assets/images/P/i9.png"
import i10 from "../../assets/images/P/i10.png"
import i12 from "../../assets/images/P/i12.png"
import i11 from "../../assets/images/P/i11.png"
import footerImage from "../../assets/images/Footer/footer6.jpg";

import CatalogCard from '../../components/Cards/catalog';

export const TurnKeySolutionsMainPage =()=>{

    const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return(
   <div>
        <ContactNavBar />
        <Nav />
        <FirstUniversalCard title="Solutions’ provider for plasturgy'" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"/>
        <ZoomCard />
        <div className='our'>
        <CatalogCard />
        </div>
        
        
         
        <BackToTopButton show={showBackToTop} />  
          <Footer backgroundImage={`url(${footerImage})`}/>

    
    </div>
    )
}

/* Second Component*/

export const TurnKeySolutionsSecondPage =()=>{

  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      
      <FirstUniversalCard title="Co-design & Product Development" text="At PLASTICUM, we believe that the path to outstanding plastic injection molding solutions begins with collaborative co-design and meticulous product development. We partner with you to turn your concepts into reality, leveraging our expertise to optimize design for manufacturability, cost-effectiveness, and performance." imag={i9}/>
      <div className='bg-white'>
      <ContentCardT1/>
      </div>
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}
/*turnkeysolution2 */
export const TurnKeySolutionsSecondPage2 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Tooling Development & Manufacturing" text="Count on PLASTICUM for cutting-edge tooling that's engineered to meet your exact specifications, from single-cavity prototypes to high-volume multi-cavity molds." imag={i8}/>
      <div className='bg-white'>
      <ContentCardT2 />
      </div>
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}
/*turnkeysolution3 */
export const TurnKeySolutionsSecondPage3 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Plastic Injection Molding" text="Our plastic injection molding expertise sets the industry standard for quality, precision, and speed. We deploy state-of-the-art machinery, materials, and processes to bring your designs to life with exceptional consistency and efficiency." imag={i10}/>
      <div className='bg-white'>
      <ContentCardT3/>
      </div>
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}

/*turnkeysolution4 */
export const TurnKeySolutionsSecondPage4 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Injection-related Services" text="Our commitment to delivering end-to-end solutions goes beyond injection molding. We offer a suite of related services to support your project, from assembly and packaging to logistics and fulfillment." imag={i12}/>
      <div className='bg-white'>
      <ContentCardT4 />
      </div>
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}
/*turnkeysolution5 */
export const TurnKeySolutionsSecondPage5 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Integrated Tool Management" text="INTEGRATED TOOL MANAGEMENT

Art of Precision /In the realm of plastic injection molding, precision is not just a goal—it's an art form. At PLASTICUM, we have dedicated ourselves to mastering this art of precision through our Integrated Tool Management approach. Precision in tooling is the foundation upon which exceptional products are built." imag={i11}/>
      <div className='bg-white'>
      <ContentCardT5 />
      
      </div>
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { MainTitle } from '../Typography/titles';
import PopupButton from 'react-calendly';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface ContactTableRow {
  name: string;
  linkedInLink: string;
  emailLink: string;
  jobPosition: string;
  departement: string;
  _id: string;
  __v: number;
}

export const ContactTableComponent: React.FC = () => {
  const [contactData, setContactData] = useState<ContactTableRow[]>([
    {
      "_id": "65040cb4f16cedaf39954e4b",
      "name": "Habib ZNAGUI",
      "linkedInLink": "https://www.linkedin.com/in/habib-znagui-66803216/",
      "emailLink": "Habib.znagui@plasticum.tn",
      "jobPosition": "Development & Industrialization Manager",
      "departement": "Development & Industrialization Department",
      "__v": 0
    },
    {
      "_id": "65040e0ff16cedaf39954e4d",
      "name": "Farouk TRAOULI",
      "linkedInLink": "https://www.linkedin.com/in/farouk-traouli-41785841/",
      "emailLink": "Farouk.traouli@plasticum.ma",
      "jobPosition": "PMI Manager",
      "departement": "Plasticum Maroc Industries",
      "__v": 0
    },
    {
      "_id": "65040ed7f16cedaf39954e4f",
      "name": "Rym DAKHLAOUI",
      "linkedInLink": "https://www.linkedin.com/in/rym-dakhlaoui-04500bb9/",
      "emailLink": "rym.dakhlaoui@plasticum.tn",
      "jobPosition": "Administration Manager",
      "departement": "Administration Department",
      "__v": 0
    },
    {
      "_id": "65040f25f16cedaf39954e51",
      "name": "Mohamed KAMOUN",
      "linkedInLink": "https://www.linkedin.com/in/mohamed-kam/",
      "emailLink": "mohamed.kamoun@plasticum.tn",
      "jobPosition": "Marketing Manager",
      "departement": "Marketing Department",
      "__v": 0
    },
    {
      "_id": "65040f6bf16cedaf39954e53",
      "name": "Khouloud JABALLAH",
      "linkedInLink": "https://www.linkedin.com/in/khouloud-jaballah-529a05259/",
      "emailLink": "Khouloud.jaballah@plasticum.tn",
      "jobPosition": "Talent Acquisition Manager",
      "departement": "Human Resources Department",
      "__v": 0
    }
  ]);

  /* useEffect(() => {
     axios.get('plasticum.tn/api/contactTable')
       .then((response) => {
         setContactData(response.data);
       })
       .catch((error) => {
         console.error('Erreur lors de la récupération des données : ', error);
       });
   }, []);*/

  // Créez un objet pour regrouper les données par département
  const groupedData: { [key: string]: ContactTableRow[] } = {};

  contactData.forEach((row) => {
    if (!groupedData[row.departement]) {
      groupedData[row.departement] = [];
    }
    groupedData[row.departement].push(row);
  });

  return (
    <div className="ml-4 mr-4 md:ml-32 md:mr-32">
      {Object.entries(groupedData).map(([department, rows], index) => (
        <div key={index} className="pb-20">
          <div className="relative overflow-hidden">
          <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xl font-bold bg-main-blue text-white h-[3rem] uppercase dark:text-gray-400 content-center">
                  <tr>
                    <th colSpan={3} className="text-center">
                      {department}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`${rowIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                        } dark:bg-gray-800`}
                    >
                      <th
                        scope="row"
                        className="px-2 md:px-6 py-4 text-xl font-medium text-main-blue dark:text-white border-r-2 border-l-2 border-b-2 w-[20rem]"
                      >
                        {row.name}
                      </th>
                      <th
                        scope="row"
                        className="px-2 md:px-6 py-4  font-medium text-xl text-gray-900 dark:text-white border-b-2 border-l-2 border-r-2 w-[40rem]"
                      >
                        {row.jobPosition}
                      </th>
                      <th className="flex justify-center items-center px-2 md:px-6 py-4 text-gray-900 dark:text-white border-b-2 border-r-2 w-[20rem]">
    <div className="flex justify-around items-center w-full">
                          <a href={row.linkedInLink} className="tooltip-container" target="_blank">
                            <div className="tooltip">
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                className="text-secondary-blue"
                                size="2x"
                              />
                            </div>
                          </a>
                          <a href={`mailto:${row.emailLink}`} className="tooltip-container">
                            <div className="tooltip">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-secondary-blue"
                                size="2x"
                              />
                            </div>
                          </a>

                        </div>
                      </th>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface EventTableRow {
  date: string;
  name: string;
  eventLink: string;
  Organiser: string;
  country: string;
  _id: string;
  __v: number;
}

export const EventsTable: React.FC = () => {
  const eventData = [
    {
      month: 'Nov-23',
      event: '7ème salon de la sous-traitance automobile',
      eventLink: 'https://www.fiev.fr/evenements/7eme-edition-du-salon-de-la-sous-traitance-automobile-a-tanger-automotive-city/',
      host: 'AMICA',
      hostLink: 'https://www.linkedin.com/company/amica-association/',
      country: 'Morocco'
    },
    {
      month: 'Oct-24',
      event: 'IZB 2024',
      eventLink: 'https://www.izb-online.com/index.html',
      host: 'IZB',
      hostLink: 'https://www.linkedin.com/company/izb-wolfsburg/',
      country: 'Germany'
    },
    // Add more events as needed
  ];
  

  return (
    <div className="ml-4 mr-4 md:ml-32 md:mr-32">
      <MainTitle
        titleText="Do you want to meet us?"
        text="Discover all the congresses where we will be present in the coming months"
      />
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xl font-bold bg-main-blue text-white h-14 uppercase dark:text-gray-400 content-center">
            <tr>
              <th className="text-center">Month</th>
              <th className="text-center">Event</th>
              <th className="text-center">Host</th>
              <th className="text-center">Country</th>
            </tr>
          </thead>
          <tbody>
            {eventData.map((data, index) => (
              <tr key={index}>
                <td className="px-2 md:px-6 py-2 md:py-4 text-xl font-medium text-main-blue whitespace-nowrap dark:text-white border-2 md:w-[10rem]">
                  {data.month}
                </td>
                <td className="px-2 md:px-6 py-2 md:py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border-2">
                  <a style={{ color: 'blue', textDecoration: 'underline' }} href={data.eventLink} className="text-xl">
                    {data.event}
                  </a>
                </td>
                <td className="px-2 md:px-6 py-2 md:py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border-2">
                  <a style={{ color: 'blue', textDecoration: 'underline' }} href={data.hostLink} className="text-xl">
                    {data.host}
                  </a>
                </td>
                <td className="py-2 md:py-4 px-2 md:px-6 text-center border-2 text-xl md:w-[10rem]">
                  {data.country}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

import React ,{useEffect,useState} from 'react'
import BackToTopButton from "../../components/Buttons/backToTop";
import Nav from "../../components/nav";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import CareersCard from '../../components/Cards/careers';
import FirstUniversalCard from "../../components/Cards/firstUniversalCard";
import i7 from "../../assets/images/P/i7.png"
import footerImage from "../../assets/images/Footer/footer6.jpg";

const Careers =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return(
    <div className="">
        <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Careers" text="Join us on a journey where your skills are celebrated, your ideas are valued, and your career ambitions are nurtured. We are committed to fostering a dynamic and inclusive workplace where every individual can thrive." imag={i7}/>
      <CareersCard />
      <BackToTopButton show={showBackToTop} />
   
        <Footer backgroundImage={`url(${footerImage})`}/>

        </div>
    )
}
export default Careers;
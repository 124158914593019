import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Logo } from '../logo';
import { NavItems } from './navItems';
//lg : pl => padding left

const NavBarContainer = styled.div `
    min-width:100%;
    min-height: 2.5rem;
    ${tw`
    w-full
    max-w-screen-2xl
    max-h-11
    flex
    flex-row
    items-center
    lg:pl-12 
    lg:pr-12
    sticky top-0
    bg-secondary-blue/60
    justify-between
    flex-row-reverse

    
    `};
`;

export function ContactNavBar (){
    return <NavBarContainer>
        <NavItems />
    </NavBarContainer>
}
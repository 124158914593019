import React from 'react';
import { BigTitle, BigTitleBlue } from '../Typography/titles';
import styled from 'styled-components/macro'; 
import { ReadMoreButton } from '../Buttons';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
const ResponsiveImage = styled.img`
  width: 100%;    
  max-width: 100%;  
  height: auto;     
  object-fit: cover; 
  display: block;   
`;


const Container = styled.div`
  display: flex;
  height: 750px;
  position: relative; 
  width:100%;
  overflow:hidden;
  @media (max-width: 1100px) {

    height:550px;
    
`;

const BlueBackground = styled.div`
  ${tw`
    absolute
    top-4  
    left-[44rem]
    rounded-tl-[30rem] 
    rounded-bl-[30rem] 
    h-[500px] 
    w-[700px]
    bg-blue-500
    opacity-40
    transition-all
    duration-300
    ml-4
    overflow-hidden
  `}
  z-index: -1; 
`;
const BlueBackgroundTwo = styled.div`
  ${tw`
    absolute
    top-[516px]  
    right-[1rem]
    h-[130px] w-[300px]
    bg-blue-500
    opacity-40
    transition-all
    duration-300
    ml-4
    overflow-hidden
  `}
  z-index: -1; 
`;
const FirstUniversalCard = ({title="",text="",imag=""}) => {
  const handleButtonClick =()=>{

  }
  return (
    <Container>
      <div className='flex flex-row lg:pl-20 ' style={{ zIndex: 1 }}>
        <BigTitleBlue titleText={title} text={text}/>
       
      </div>
      <ResponsiveImage src={imag} alt="description" />
    </Container>
  );
};

export default FirstUniversalCard;

import React, { useState, useEffect ,useRef} from 'react';
import { MainTitle } from "../Typography/titles";
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import firstimg from "../../assets/images/firstimg.jpg"
import secondimg from "../../assets/images/secondimg.jpg"
import empl from "../../assets/images/MUR.png"
import { Reveal } from '../Reveal';
import Marquee from 'react-fast-marquee';


export const MeetFamilyCard = () => {
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 0); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  const settings = {
    infinite: true, 
    speed: 7000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
  };

  const emplimg = [
    { id: 1, imageUrl: empl },

  ];

  return (
    <Reveal>
  <div>
    <Reveal>
    <MainTitle titleText="Meet our Family " text="Our success is driven by the talent, dedication, and creativity of our innovators" />
  </Reveal>
      <div className='-mt-5'>
      <Marquee direction="right" speed={100} delay={5}>
          <div className="image_wrapper">
          {emplimg.map((emplimg) => (
            <div key={emplimg.id}>
              <img
                src={emplimg.imageUrl}
                alt={`emplimg ${emplimg.id}`}
                className="w-full h-auto" 
              />
            </div>
          ))}
        </div>
        </Marquee>
</div>
</div>
</Reveal>
  );
};


export const JoinFamilyCard =() =>{
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 0); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 7000, // speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
  };

  const emplimg = [
    { id: 1, imageUrl: empl },

  ];

  return (
    
    <Reveal>
      <div style={{marginTop:"-80px"}}> {/* Adjust the negative margin here */}
        <Reveal>
        <MainTitle titleText="Join Our Team of Innovators " text="Our success is driven by the talent, dedication, and creativity of our innovators" />
      </Reveal>
          <div >
          <Marquee direction="right" speed={100} delay={5}>
              <div className="image_wrapper">
              {emplimg.map((emplimg) => (
                <div key={emplimg.id}>
                  <img
                    src={emplimg.imageUrl}
                    alt={`emplimg ${emplimg.id}`}
                    className="w-full h-auto" 
                  />
                </div>
              ))}
            </div>
            </Marquee>
    </div>
    </div>
    </Reveal>
      );
    };
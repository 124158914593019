import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const CardButtonLeft = styled.button`
  ${tw`
  bg-secondary-blue
  w-[4rem]
  h-[4rem]
    rounded-tl-[2rem]
    rounded-tr-[2rem]
    rounded-br-[2rem]
    flex items-center
    transition-all
    duration-500
    items-center
  `}

  &:hover {
    width: 7rem;
    justify-content: flex-start;
  }
  /* Rotate a bit when hovered */
  &:hover {
    width: 8rem;
    justify-content: flex-start;
  }

  /* Rotate the plus icon slightly */
  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: rotate(45deg);
  }
`;

const CardButtonRight = styled.button`
  ${tw`
    bg-secondary-blue
    w-[4rem]
    h-[4rem]
    rounded-tl-[2rem]
    rounded-tr-[2rem]
    rounded-bl-[2rem]
    flex items-center
    transition-all
    duration-500
    items-center
  `}

  &:hover {
    width: 7rem;
    justify-content: flex-start;
  }
  /* Rotate a bit when hovered */
  &:hover {
    width: 7rem;
    justify-content: flex-start;
  }

  /* Rotate the plus icon slightly */
  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: rotate(45deg);
  }
`;

const ButtonText = styled.span`
  ${tw`
    text-white
    mr-2
    pl-4
    opacity-0
    transition-opacity
    duration-500
    font-bold
  `}

  ${CardButtonLeft}:hover & {
    opacity: 1;
    animation: buttonOpen 0.9s ease forwards;
  }
`;

const ButtonTextRight = styled.span`
  ${tw`
    text-white
    -ml-2
    mr-2
    pl-4
    opacity-0
    transition-opacity
    duration-500
    font-bold
  `}

  ${CardButtonRight}:hover & {
    opacity: 1;
      animation: buttonOpen 0.9s ease forwards;
  }
`;

const ButtonContent = styled.div`
  ${tw`
    flex items-center
    justify-center
    pl-[1rem]

  `}
  
`;

export const MainButtonLeft = ({ link }: { link: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
  };

  return (
    <CardButtonLeft onClick={handleClick}>
      <ButtonContent>
        <FontAwesomeIcon icon={faPlus} color='white' size='2xl' />
        <ButtonText>More</ButtonText>
      </ButtonContent>
    </CardButtonLeft>
  );
};


export const MainButtonRight = ({ link }: { link: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
  };

  return (
    <CardButtonRight onClick={handleClick}>
      <ButtonContent>
        <FontAwesomeIcon icon={faPlus} color='white' size='2xl' />
        <ButtonTextRight>More</ButtonTextRight>
      </ButtonContent>
    </CardButtonRight>
  );
};



interface GetRfiButtonProps {
      onClick: () => void;
        fontSize?: string; // Optional fontSize prop
      height?:string;
}
    
export const GetRfiButton: React.FC<GetRfiButtonProps> = ({ onClick,fontSize,height }) => {
    return (
      <button         style={{ fontSize: fontSize,height:height }} 
 className="rounded-[10px] bg-secondary-blue w-[200px] h-10  text-white font-bold" onClick={onClick}>
       Contact us 
      </button>
    );
};
      
interface ReadMoreButtonProps {
      onClick: () => void;
      text:any;
}
    
export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({text, onClick }) => {
    return (
      <button className="border rounded-[10px] bg-secondary-blue w-[200px] h-12 mt-10 text-white font-bold flex items-center justify-center md:w-[150px] md:h-10">
      {text}
    </button>
    
    );
};
      
interface ZoomButtonProps {
    buttonText: string;
    isSelected: boolean; 
    onClick: () => void;
    customClass?: string;
}

export const ZoomButton: React.FC<ZoomButtonProps> = ({ buttonText, isSelected, onClick, customClass }) => {
  return (
    <motion.div
      className={`bg-white rounded-[20px] items-center justify-center text-center ${
        isSelected ? 'scale-110' : '' 
      }`}
      initial={{ scale: 1 }}
      animate={{ scale: isSelected ? 1.2 : 1 }}
      transition={{ type: 'spring', stiffness: 200, damping: 30 }}
    >
      <button className={`text-[18px] pt-1 ${customClass}`} onClick={onClick}>
        {buttonText}
      </button>
    </motion.div>
  );
};

interface MyButtonProps {
  onClick: () => void;
}

export const MyButton: React.FC<MyButtonProps> = ({ onClick }) => {
  return (
    <button
      className="plusButton relative bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-all overflow-hidden"
      onClick={onClick}
    >
      <i className="fas fa-plus absolute left-0 top-1/2 transform -translate-y-1/2 mr-2"></i>
      <span className="read-more-text absolute left-full top-1/2 transform -translate-y-1/2 opacity-0 transition-opacity">
        Read More
      </span>
    </button>
  );
};







import { Typography } from "@material-tailwind/react";
import LogoMark from "../../assets/images/logomarkWhite.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import footerImage from "../../assets/images/Footer/footer6.jpg";
import tn from "../../assets/images/tn.png";
import mr from "../../assets/images/mr.png";


export const Footer = ({ backgroundImage = "url(default_image_url_here.jpg)", className = "" }) => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const footerStyle = {
        backgroundImage: `${backgroundImage}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    };

    return (
        <div>

            <div className="mt-10 bg-black" style={footerStyle}>

                <div className="flex flex-col md:flex-row mx-4 md:mx-24">

                    <div className='w-full md:w-1/4'>
                        <ul className='text-white text-center md:text-left mt-4 md:mt-16 space-y-5'>
                            <li><Link to="/company"><p className="hover:text-secondary-blue">Company</p></Link></li>
                            <li><Link to="/careers"><p className="hover:text-secondary-blue">Careers</p></Link></li>


                            <li className="text-gray-300 font-extrabold">TURNKEY SOLUTION</li>
                            <li><Link to="/co-design-product-development" onClick={scrollToTop}><p className="hover:text-secondary-blue">Co-design & Product Development</p></Link></li>
                            <li><Link to="/tooling-development-manufacturing" onClick={scrollToTop}><p className="hover:text-secondary-blue">Tooling Development & Manufacturing</p></Link></li>
                            <li><Link to="/plastic-injection-molding" onClick={scrollToTop}><p className="hover:text-secondary-blue">Plastic Injection Molding</p></Link></li>
                            <li><Link to="/injection-related-services" onClick={scrollToTop}><p className="hover:text-secondary-blue">Injection-related Services</p></Link></li>
                            <li><Link to="/integrated-tool-management" onClick={scrollToTop}><p className="hover:text-secondary-blue">Integrated Tool Management</p></Link></li>
                            <br></br>
                            <br></br>
                        </ul>
                    </div>

                    <div className='w-full md:w-1/5'>
                        <ul className='text-white text-center md:text-left mt-4 md:mt-16 space-y-5'>

                            <li className="text-gray-300 font-extrabold ">INDUSTRIES</li>
                            <li><Link to="/automotive" onClick={scrollToTop}><p className="hover:text-secondary-blue">Automotive</p></Link></li>
                            <li><Link to="/domotic" onClick={scrollToTop}><p className="hover:text-secondary-blue">Domotive</p></Link></li>
                            <li><Link to="/other-industries" onClick={scrollToTop}><p className="hover:text-secondary-blue">Other Industries</p></Link></li>
                        </ul>
                    </div>
                    <div className='w-full md:w-1/5'>
                        <ul className='text-white text-center md:text-left mt-4 md:mt-16 space-y-5 mb-20'>

                            <li className='text-gray-300 font-extrabold '>WANT TO JOIN THE TEAM?</li>
                            <li className=''><a href="mailto:recrutement@plasticum.tn">recrutement@plasticum.tn</a></li>
                            <li className='text-gray-300 font-extrabold '>REQUEST A QUOTE</li>
                            <li className=''><a href="mailto:Habib.znagui@plasticum.tn">sales@plasticum.tn</a></li>
                            <li className='pt-4'>
                                <a href="https://www.linkedin.com/company/plasticumtunisie/"><FontAwesomeIcon icon={faLinkedinIn} className='pr-4' size="2x" /></a>
                            </li>
                        </ul>
                    </div>
                    <div style={{ width: "30%" }} className=' mb-20 ml-[10px] justify-center items-center'>
                        <ul className='text-white text-left space-y-5  mt-4 md:mt-16 sm:flex sm:space-y-0 sm:space-x-8'>
                            <li className='relative flex-shrink-0'>
                                <img src={tn} className="absolute " style={{ width: "30%" }}></img>
                                <div className='pl-12'>
                                    <p className='text-2xl text-gray-300 font-extrabold'>Tunisia</p>
                                    <p style={{ marginTop: "12px" }}>Z.I. Innopark 8 & 9<br></br> El Agba 2087 Tunis</p>
                                </div>

                            </li>
                            <iframe style={{marginLeft:"14%"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54035.422724800934!2d10.027963196684103!3d36.793611549698554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd2dfa85fc62cf%3A0x85f9af93c92cb273!2sPLASTICUM%20TUNISIE!5e0!3m2!1sfr!2stn!4v1703864222845!5m2!1sfr!2stn" width="40%" height="60%"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                        </ul>
                        <ul className='text-white text-left space-y-5  mt-4 md:mt-10 sm:flex sm:space-y-0 sm:space-x-8'>

                            <li className='relative flex-shrink-0'>
                                <img src={mr} className="absolute pr-2" style={{ width: "30%" }}></img>

                                <div className='pl-12'>
                                    <p className='text-2xl text-gray-300 font-extrabold'>Maroc</p>
                                    <p style={{ marginTop: "12px" }}>Atlantic Free Zone <br></br> Ilot I36/37 Kenitra</p>
                                </div>

                            </li>
                            <iframe style={{marginLeft:"15.5%"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52764.50671330735!2d-6.630216395590683!3d34.2541143536981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7575f8a6d8643%3A0xc7050653c05e128b!2zS8Opbml0cmEsIE1hcm9j!5e0!3m2!1sfr!2stn!4v1703863471343!5m2!1sfr!2stn" width="40%" height="60%"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                        </ul>
                    </div>

                </div>
            </div>

        </div>
    )

}




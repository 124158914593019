import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img1 from "../../assets/images/PIM/456A8750-2.jpg";
import img2 from "../../assets/images/PIM/456A8840-2.jpg";
import img3 from "../../assets/images/PIM/456A8843-2.jpg";
import img4 from "../../assets/images/PIM/BI-INJECTION-2.jpg";
import img5 from "../../assets/images/PIM/FERMETURE ACCELERE PRESSE.jpg";
import img6 from "../../assets/images/PIM/ILOT 2.jpg";
import img7 from "../../assets/images/PIM/PLATEAU ROTATIF.jpg";import { useEffect} from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color:#16A4CC;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-60
    relative
    overflow-hidden
  `}
  background-color:#939093;

  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground5 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  
  background-color:#D5B47C;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-70
    relative
    overflow-hidden
   
  `}
  background-color:#DA5E64;

`;
const BlueBackgroundL4 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color:#2E6EBD;
`;
const BlueBackgroundL6 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-60
    relative
    overflow-hidden
   
  `}
  background-color:#008EE7;
`;
const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;
export function ContentCardT3 (props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Technological Powerhouse</CardTitle>
            <CardHr />
            <CardText>
            Our injection molding machines are at the forefront of technology. They are equipped with advanced features such as servo-driven systems, precision control, and high-speed capabilities. This technology ensures that every molded part meets the tightest tolerances and quality standards.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img6} />
  <BlueBackground />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img5} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Wide Span of Clamping Force</CardTitle>
            <CardHr />
            <CardText>
            We take pride in our diverse range of injection molding machines, each equipped with a specific clamping force tailored to meet the unique requirements of your projects. Our extensive clamping force capabilities, spanning from 50 tons (50T) to a robust 850 tons (850T), allow us to provide you with a precise and comprehensive manufacturing solution.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Materials Matter</CardTitle>
            <CardHr />
            <CardText>
            The selection of the right plastic material is paramount in injection molding. A wide range of thermoplastics, thermosetting plastics, and elastomers are available, each with unique properties. Material selection is influenced by factors such as strength, flexibility, chemical resistance, and environmental considerations. Understanding material properties is crucial for achieving the desired performance and aesthetics of the final product
            </CardText>
          
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
          <CardImgR src={img2} />
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 

<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img4}/>
        <BlueBackgroundL4/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Bi-Injection Molding</CardTitle>
            <CardHr />
            <CardText>
            Bi-injection molding, also known as two-shot molding or 2K molding, is a groundbreaking technique that allows for the injection of two different materials or colors into a single mold, producing a two-color or two-material component. This technology opens up a world of creative and functional possibilities. It's often used for creating intricate designs, enhancing product aesthetics, and combining materials with varying properties, such as soft and hard plastics.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Overmolding & Insert Molding</CardTitle>
            <CardHr />
            <CardText>
            Overmolding allows us to seamlessly encapsulate existing parts with a layer of precision-molded plastic, enhancing durability and ergonomics. Meanwhile, insert molding enables us to efficiently integrate non-plastic components, such as metal fasteners or electrical elements, into the heart of plastic parts.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img1} />
  <BlueBackground5 />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img7} />
        <BlueBackgroundL6/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >360° Solution</CardTitle>
            <CardHr />
            <CardText>
            From the initial concept and design phases to material selection, tooling development, precision molding, quality control, and even integrated tooling management – our commitment to excellence spans the entire manufacturing journey. Our 360° solution isn't just about producing parts; it's about forging lasting partnerships and delivering unparalleled results.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>


    </>
  );
}

import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';

const ListContainer = styled.ul`
  ${tw`
    flex
    list-none 
    justify-between
    space-x-4
    m-0
  `};

  @media (max-width: 768px) {
    ${tw`
      mr-0
      space-x-2
    `};
  }
`;

const NavItem = styled.li`
  ${tw`
    text-sm
    md:text-base
    text-main-blue
    font-bold
    mr-1
    md:mr-3
    transition
    duration-300
    ease-in-out
    content-end
  `};

  @media (max-width: 768px) {
    ${tw`
      text-xs
      md:mr-2
    `};
  }
`;

const StyledLink = styled.a`
  ${tw`
    text-main-blue
    font-bold
    flex
    items-center
  `}
`;

export function NavItems() {
  return (
    <ListContainer>
      <NavItem>
        <StyledLink href="mailto:contact@plasticum.tn">
          <FontAwesomeIcon icon={faMailBulk} size='lg' className='mr-3' />
          sales@plasticum.tn
        </StyledLink>
      </NavItem>
      <NavItem>
        <p className='mr-8'>
          <FontAwesomeIcon icon={faPhone} size='lg' className='mr-3' />
          +216 31 383 044
        </p>
      </NavItem>
    </ListContainer>
  );
}

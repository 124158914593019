import {JoinFamilyCard} from "./familyCard";
import LifePTCard from "./lifePTCard";
import {LifePT} from "./lifePTCard2";
import OpenPositionsCard from "./openPositionsCard";

const CareersCard =() =>{
return(
    <>
    <JoinFamilyCard />
    <OpenPositionsCard />


    <LifePT/>
    
    </>
)

}
export default CareersCard;
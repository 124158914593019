import React, { useState,useEffect } from 'react';
import achievments from "../../assets/images/comp12.jpg";
import axios from 'axios';
import { Reveal } from '../Reveal';

const CatalogCard: React.FC = () => {
  const [popupContent, setPopupContent] = useState<any | null>(null);
  const imageWidth = 100;
  const imageHeight = 69;

  const showPopup = async (productName: string) => {
    try {
      const response = await axios.get(`plasticum.tn/api/achievments/${productName}`);
      setPopupContent(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const closePopup = () => {
    setPopupContent(null);
  };


  useEffect(() => {
    setPopupContent(null);
  }, []);

  return (
    <Reveal>
<div>

    <div className="relative mt-10 zIndex=1">

    <style>
      {`
        @media (max-width: 1000px) {
          .achievements-image {
            margin-left: 0px; 
            width: auto; 
            max-width: 100%; 
            height: auto; 
          }
          .test {
            position: absolute;
            z-index: 2; 
            margin-top: -150px;
            right: 10px;
          }
          .test p {
            font-size: 16px;
          }
        }
      `}
    </style>
    <div style={{ position: 'relative' }}>
  <img src={achievments} alt="Achievements Image" className='w-full h-[750px]  achievements-image' />
  <div style={{ position: 'absolute', top: '-600px', left: 0, right: 0, bottom: 0 }} className='flex flex-col items-center justify-center text-center pb-8 '>
    <p className='text-[40px] font-bold text-main-blue '>Achievements</p>
    <hr className='w-36 h-1 mt-4 bg-secondary-blue border-0 rounded' />
  </div>
</div>


      {/* Zone cliquable pour le produit 1 */}
      <div
  className='absolute z-10'
  style={{
    position: 'absolute',
    left: `${imageWidth * 0.23}%`,
    top: `${imageHeight * 0.31}%`,
    width: '7.5%',
    height: '13%',
    cursor: 'pointer',
    backgroundColor: '',
  }}
  onClick={() => showPopup('acoudoir')}
/>
          <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.36}%`,
          top: `${imageHeight * 0.30}%`,
          width: '14%',
          height: '15%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0 20px 40px 0px'
        }}
        onClick={() => showPopup('acoudoir')}
      />
          <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.335}%`,
          top: `${imageHeight * 0.46}%`,
          width: '5%',
          height: '4%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0 0px 0 120px'
        }}
        onClick={() => showPopup('acoudoir')}
      />
 <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.335}%`,
          top: `${imageHeight * 0.305}%`,
          width: '5%',
          height: '4%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'120px 0px 0 0px'
        }}
        onClick={() => showPopup('acoudoir')}
      />
      {/* Zone cliquable pour le produit 2 */}
      <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.63}%`,
          top: `${imageHeight * 0.24}%`,
          width: '16%',
          height: '15%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'40px 0px 0 50px'
        }}
        onClick={() => showPopup('Produit 2')}
      />
     {/* Zone cliquable pour le produit 3 */}
     <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.52}%`,
          top: `${imageHeight * 0.29}%`,
          width: '9%',
          height: '15%',


          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0 120px 0 120px',

        }}
        onClick={() => showPopup('piece3')}
      />

      {/* Zone cliquable pour le produit 4 */}
      <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.22}%`,
          top: `${imageHeight * 0.56}%`,
          width: '10%',
          height: '15%',

          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 0px 0px 0px',
        }}
        onClick={() => showPopup('piece4')}
      />
      {/* Zone cliquable pour le produit 4 */}
      <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.22}%`,
          top: `${imageHeight * 0.88}%`,
          width: '8%',
          height: '15%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 0px 0px 0px',
        }}
        onClick={() => showPopup('piece4')}
      />
            {/* Zone cliquable pour le produit 4 */}
            <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.22}%`,
          top: `${imageHeight * 1.22}%`,
          width: '11%',
          height: '9%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 0px 0px 0px',
        }}
        onClick={() => showPopup('piece4')}
      />
        {/* Zone cliquable pour le produit 4 */}
        <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.365}%`,
          top: `${imageHeight * 1.27}%`,
          width: '4%',
          height: '6%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 0px 0px 0px',
        }}
        onClick={() => showPopup('piece4')}
      />
           {/* Zone cliquable pour le produit 5 */}
           <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.44}%`,
          top: `${imageHeight * 1.22}%`,
          width: '14%',
          height: '12.5%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece5')}
      />

      {/* Zone cliquable pour le produit 6 */}
      <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.57}%`,
          top: `${imageHeight * 0.87}%`,
          width: '7%',
          height: '20%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 50px 50px'
        }}
        onClick={() => showPopup('piece6')}
      />
           {/* Zone cliquable pour le produit 7 */}
           <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.665}%`,
          top: `${imageHeight * 1.23}%`,
          width: '11%',
          height: '11%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
  {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.61}%`,
          top: `${imageHeight * 1.27}%`,
          width: '2%',
          height: '5%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
  {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.67}%`,
          top: `${imageHeight * 1.03}%`,
          width: '9%',
          height: '7%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
       {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.67}%`,
          top: `${imageHeight * 0.95}%`,
          width: '9%',
          height: '3%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
     {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.67}%`,
          top: `${imageHeight * 0.78}%`,
          width: '9%',
          height: '5%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
           {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.665}%`,
          top: `${imageHeight * 0.59}%`,
          width: '10%',
          height: '8%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
         {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.37}%`,
          top: `${imageHeight * 0.61}%`,
          width: '2%',
          height: '8.7%',
          cursor: 'pointer',
          backgroundColor: '',
        }}
        onClick={() => showPopup('piece2')}
      />
        {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.42}%`,
          top: `${imageHeight * 0.61}%`,
          width: '4%',
          height: '8.7%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 50px 50px 50px'
        }}
        onClick={() => showPopup('piece2')}
      />
         {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.43}%`,
          top: `${imageHeight * 0.81}%`,
          width: '3%',
          height: '8.7%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 50px 50px'
        }}
        onClick={() => showPopup('piece2')}
      />
         {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.36}%`,
          top: `${imageHeight * 0.84}%`,
          width: '2%',
          height: '20%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 50px 0px'
        }}
        onClick={() => showPopup('piece2')}
      />
        {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.44}%`,
          top: `${imageHeight * 0.97}%`,
          width: '7.5%',
          height: '9%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 0px 0px'
        }}
        onClick={() => showPopup('piece2')}
      />
        {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.49}%`,
          top: `${imageHeight * 0.73}%`,
          width: '6%',
          height: '10.4%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 50px 50px'
        }}
        onClick={() => showPopup('piece2')}
      />
       {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.51}%`,
          top: `${imageHeight * 0.82}%`,
          width: '2%',
          height: '9%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'0px 0px 0px 0px'
        }}
        onClick={() => showPopup('piece2')}
      />
       {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.47}%`,
          top: `${imageHeight * 0.58}%`,
          width: '9%',
          height: '8%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 0px 0px'
        }}
        onClick={() => showPopup('piece2')}
      />
       {/* Zone cliquable pour le produit 7 */}
  <div
        className='absolute z-10'
        style={{
          position: 'absolute',
          left: `${imageWidth * 0.58}%`,
          top: `${imageHeight * 0.61}%`,
          width: '4%',
          height: '10%',
          cursor: 'pointer',
          backgroundColor: '',
          borderRadius:'50px 50px 50px 90px'
        }}
        onClick={() => showPopup('piece2')}
      />
{popupContent && (

  <div className='fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50'>
    <div className='bg-white p-6 rounded-lg shadow-xl relative' style={{ width: '400px' }}>
      <button
        className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
        style={{ zIndex: 2 }}
        onClick={closePopup}
      >
        <svg
          xmlns='https://www.w3.org/2000/svg'
          className='h-6 w-6'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>

      <img
        src={popupContent[0].image}
        alt={popupContent[0].nom}
        style={{ width: '300px', height: '300px' }}
      />

      <p>{popupContent[0].text}</p>
    </div>
  </div>
)}


</div>
    </div>
    </Reveal>
  );
};

export default CatalogCard;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Blog, CaseStudy } from '../types';
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
  `}
`;



const Press1: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('Press');
  const [selectedOption, setSelectedOption] = useState<string>('Recommended');
  const [currentPage, setCurrentPage] = useState<number>(1);


  const optionValues = ['Recommended', 'Latest', 'Top'];

  const cardsPerPage = 5;

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('plasticum.tn/api/blog');
      setBlogs(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des blogs :', error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);


  const handleCategoryChange = async (selectedCategory: string) => {
    setSelectedCategory(selectedCategory);
    setCurrentPage(1);


    if (selectedCategory === 'all') {
      fetchBlogs();
    } else {

      try {
        const response = await axios.get(`plasticum.tn/api/blog/category?category=${selectedCategory}`);
        setBlogs(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des blogs par catégorie :', error);
      }
    }
  };


  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };


useEffect(() => {
  const fetchFilteredBlogs = async () => {
    try {
      let response;
      if (selectedCategory === 'all') {

        if (selectedOption === 'Recommended') {
          response = await axios.get('plasticum.tn/api/blog/recommended');
        } else {
          response = await axios.get('plasticum.tn/api/blog/date');
        }
      } else {

        if (selectedOption === 'Recommended') {
          response = await axios.get(`plasticum.tn/api/blog/category/recommended?category=${selectedCategory}`);
        } else {
          response = await axios.get(`plasticum.tn/api/blog/category/date?category=${selectedCategory}`);
        }
      }
      setBlogs(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des blogs filtrés :', error);
    }
  };

  fetchFilteredBlogs();
}, [selectedCategory, selectedOption]);


 // Pagination
const totalPages = Math.ceil(blogs.length / cardsPerPage);
const startIndex = (currentPage - 1) * cardsPerPage;
const endIndex = startIndex + cardsPerPage;
const currentBlogCards = blogs.slice(startIndex, endIndex);

const handlePrevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};

const handleNextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

const handleTagClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>

    <div className="mx-20 my-20 ">
      <div className=" flex grid grid-cols-2 gap-4 pl-10 pt-10 bg-gray-200 rounded-lg h-[500px]">
        <div className=" flex flex-col place-items-start justify-start left-0">
            <CardTitle>Press</CardTitle>
            <CardHr />
            <CardText>Text </CardText>
            <p className="pt-8 font-bold">25/07/2023</p>
        </div>
        <div className=" overflow-hidden">
          <img className="z-10 relative h-full pb-[4rem] pr-[4rem] pt-[2rem] w-full rounded-lg" src="https://www.positive.news/wp-content/uploads/2019/02/Utslipp-av-bales-Infinitum-pantemottak-Heia-7-e1588331384174-1800x0-c-center.jpg" />
          <div className='relative
            top-[-450px]
            left-[1rem]
            w-[35rem]
            h-[23rem]
            bg-blue-500
            opacity-40
            transition-all
            duration-300
            ml-4
            ' />
        </div>
      </div>
    </div>
      <div className="mx-20 my-8">
      <div className="lg:flex justify-right mb-8 space-x-20">
      <hr className="z-10 absolute my-8 bg-main-blue h-0.5 mt-[36.4px] dark:bg-black sm:w-[1350px]"/>

          {['all', 'News', 'Events', 'Press'].map((filter) => (
            <button
              key={filter}
              className={`px-3 py-1 mx-2 font-bold ${
                selectedCategory === filter
                  ? 'z-10 underline underline-offset-8 decoration-[4px] text-secondary-blue text-2xl'
                  : ' text-main-blue text-lg'
              }`}
              onClick={() => handleCategoryChange(filter)}
            >
              {filter === 'all' ? 'All ' : filter}
            </button>
          ))}
        </div>
        <div className="flex-end flex justify-end pb-8 space-x-20">
          <select
            className="px-3 py-1 mx-2 font-bold text-main-blue text-lg bg-gray-100 border-2 border-secondary-blue"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            {optionValues.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {currentBlogCards.map((blogItemData) => (
          <div
            key={blogItemData.id}
            className="bg-bg-blue p-4 rounded-lg shadow-md mb-4 hover:bg-gray-300 cursor-pointer"
          >
            <Link to={blogItemData.link}>
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-4">
                <div className="flex flex-col space-y-2 md:w-2/3">
                  <div className="flex flex-wrap space-x-2">
                  {blogItemData.tags.map((tag, index) => (
                    <Link
                        key={index}
                        to={`/results?tag=${tag}`}
                        className="text-blue-500"
                        onClick={handleTagClick}
                    >
                        #{tag}
                    </Link>
                    ))}
                  </div>
                  <TextContainer>
                    <CardTitle>{blogItemData.title}</CardTitle>
                    <CardHr />
                    <CardText>{blogItemData.text}</CardText>
                    <p className="pt-8 font-bold">
                      {blogItemData.date ? new Date(blogItemData.date).toLocaleDateString() : 'Date invalide'}
                    </p>
                    <p className="absolute font-bold lg:pt-[370px] pt-[600px]">Clicks : {blogItemData.numOfClicks}</p>
                  </TextContainer>
                </div>
                <div className="lg:w-[400px] sm:w-[250px]">
                  <img
                    src={blogItemData.imageUrl}
                    alt={blogItemData.title}
                    className="w-[400px] h-[400px] rounded-lg pb-4 pr-2"
                  />
                </div>

              </div>
            </Link>
          </div>
        ))}
      </div>
      {blogs.length > cardsPerPage && (
      <div className="flex justify-center mt-4 space-x-4">
        <button
          className="px-3 py-1 text-lg font-bold text-white bg-secondary-blue rounded-lg"
          disabled={currentPage === 1}
          onClick={handlePrevPage}
        >
          Prev
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 text-lg font-bold ${
              currentPage === index + 1 ? 'text-secondary-blue underline underline-offset-8' : 'text-main-blue'
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="px-3 py-1 text-lg font-bold text-white bg-secondary-blue rounded-lg "
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    )}
    </div>
  );
};

export default Press1;

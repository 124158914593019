import {useEffect,useState} from 'react'
import BackToTopButton from "../../components/Buttons/backToTop";
import Nav from "../../components/nav";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import ContactCard from '../../components/Cards/contact';
import footerImage from "../../assets/images/Footer/footer6.jpg";


const ContactUs =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return(
    <div className="">
        <ContactNavBar />
      <Nav />
      <ContactCard />
      <BackToTopButton show={showBackToTop} />
        <Footer backgroundImage={`url(${footerImage})`}/>

        </div>
    )
}
export default ContactUs;
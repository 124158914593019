import React from 'react';
import styled from 'styled-components'; // Importez styled-components
import hbib from "../../assets/images/hbib.jpg";
import tw from 'twin.macro';
import { Reveal } from '../Reveal';

const CardTitle = styled.p`
  font-size: 36px;
  font-weight: bold;
  color: #003268;
  text-align: justify;
  justify-content: start;
`;

const CardHr = styled.hr`
  width: 150px;
  height: 3px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: #003268;
  border: 0;
  border-radius: 4px;
`;
const CardTit = styled.p`
  ${tw`
    text-[px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  margin-right:100px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
`;

const CardText = styled.div`
  flex: 1;
  margin-left: 100px;
  margin-right: 20px;
  line-height: 2; 
  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const CardImageContainer = styled.div`
  width: 31rem; // Adjusted to match the first component
  height: 25rem; // Adjusted to match the first component
  margin-left: 10px;
  border-radius:120px  0 170px  0; // Adjusted to match the first component
  overflow: hidden;
  position: aboslute;
  z-index: 1;
  @media (max-width: 768px) {
    width: 20rem; // Adjusted for smaller screens
    height: 15rem; // Adjusted for smaller screens
    margin-left: 0;
    position: relative; // Changed for mobile
  }

`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[170px]
    rounded-br-[120px]
    absolute
    mt-4
    ml-4 
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    top-[7.5rem]  
  `}
  
  background-color: #969BAE; // Adjust the color to match the original component
  z-index: -1; // Ensure it stays behind the image
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



const Directeur = () => {
    return (
      <Reveal>
<div className='h-[1490px] md:h-[600px]'>
        <div className="mx-24 ">
          <CardTitle>President's messsage</CardTitle>
          <CardHr />
        </div>
        <CardContainer>
          <CardText>
            <p style={{textAlign:"justify"}}>
            In an industrial world marked by increasingly high demands, and a continuous need for competitiveness and performance, PLASTICUM has chosen to develop with a focus on customer listening, anticipating market expectations, continuous performance improvement, cost control, and increasing the industrial added value related to its areas of expertise.

From product design/development to integrated tooling management in serial production, including tooling manufacturing, injection, decoration, and assembly, it is through mastering all these services that we aim to highlight the overall dynamic of our future development.

This dynamic is accompanied, in parallel, by mastery of the 'Turnkey Solutions' or 'One Stop Shop' concept, and the introduction of new technologies; all this with the goal of supporting our clients' development.

Thanks to a multidisciplinary team, accumulating a level of expertise and know-how compatible with the highest demands, we are now giving ourselves the means to provide the right solutions for our clients' projects.

Our efforts, therefore, focus on mastering industrial added value, which we know how to make available to our clients, with the highest levels of quality and service.

            </p>
          </CardText>
          
         
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CardImageContainer>
                <CardImage src={hbib} alt="Directeur" />
               
        <BlueBackgroundL />
        </CardImageContainer>
       <CardTit style={{ marginTop: '40px' }}>Mr.Habib KAMOUN - President & CEO of PLASTICUM</CardTit>

        </div>
    </CardContainer>

      </div>
      </Reveal>
    );
  }
  
  export default Directeur;
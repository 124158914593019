import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import certif from '../../assets/images/certif.png';
import { Reveal } from "../Reveal";
import { MainTitle } from "../Typography/titles";

interface Certification {
  logoUrl: string;
  pdfUrl: string;
}

const CertificationsCard = () => {
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [selectedCertification, setSelectedCertification] = useState<Certification | null>(null);

  useEffect(() => {
    // Your provided JSON data
    const jsonData = [
      { "_id": "64fb179a1011efb1aca1a5f8", "name": "certif2", "logoUrl": "https://res.cloudinary.com/dec07srhw/image/upload/v1694156607/certif_ackfbf.png", "pdfUrl": "https://drive.google.com/file/d/1R9-01NZL3xlC9OmEvn4jgfbHWnZx1c4X/view?usp=sharing", "__v": 0 },
      { "_id": "64fb179f1011efb1aca1a5fa", "name": "certif1", "logoUrl": "https://res.cloudinary.com/dec07srhw/image/upload/v1695637562/certif1_hezpfq.jpg", "pdfUrl": "https://drive.google.com/file/d/1KXp-i3BN5DGyhf7luZQZYWnledevyTqM/view?usp=drive_link", "__v": 0 },
      { "_id": "64fb1bf91011efb1aca1a610", "name": "certif1", "logoUrl": "https://res.cloudinary.com/dec07srhw/image/upload/v1695308820/cropped-Logo-MSI-20000_zmp1pz.png", "pdfUrl": "https://drive.google.com/file/d/18lNbGFo-kCWEtHyEskcUipkI0Fl3DuK-/view?usp=drive_link", "__v": 0 }
    ];

    // Extracting the required data
    const extractedData: Certification[] = jsonData.map(certif => ({
      logoUrl: certif.logoUrl,
      pdfUrl: certif.pdfUrl
    }));

    setCertifications(extractedData);

  }, []);

  const handleCertificationClick = (index: number) => {
    const selected = certifications[index];
    if (selected) {
      window.open(selected.pdfUrl, '_blank');
    }
  };
  const handleRightClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>, pdfUrl: string | URL | undefined) => {
    event.preventDefault(); // Prevent the default right-click menu
    window.open(pdfUrl, '_blank'); // Open the PDF URL in a new tab
  };
  return (
    <div className="">
      <Reveal>
        <MainTitle titleText="Certifications" text="Certified Excellence: Precision, Quality, and Client Confidence" />
      </Reveal>
      <Reveal>
        <StyledContainer   className="p-8 bg-white max-w-3xl mx-auto -mt-12 ">
          <div  className="flex items-center justify-center space-x-11 mt-0">
            <StyledImagesContainer  >
              {certifications.map((certification, index) => (
                <div className="certification-container" onClick={() => handleCertificationClick(index)}>
                  
                  <img
              onContextMenu={(event) => handleRightClick(event, certification.pdfUrl)} // Add right-click handler here

                    src={certification.logoUrl}
                    alt={`certification ${index + 1}`}
                    className={index < 2 ? "certification-logo1" : "certification-logo"}
                  />
                </div>
              ))}

            </StyledImagesContainer>
          </div>
        </StyledContainer>
      </Reveal>
    </div>
  );
};

export default CertificationsCard;



const StyledContainer = styled.div`
  ${tw`flex items-center justify-center`}
  position: relative; /* Or 'absolute' depending on your layout needs */
  margin-top: -12px; /* Adjust as needed to prevent cropping */
  padding: 8px;
  background-color: white;
  max-width: 3xl;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:50px
`;


const StyledImagesContainer = styled.div`
  ${tw`flex justify-center items-center`}
  flex-direction: column; 
  .certification-container {
    margin-bottom: 0; 
    ${tw`rounded-full h-[11rem] w-[11rem] flex justify-center items-center p-2 bg-white border border-gray-400 shadow-lg m-2`}
    overflow:hidden;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
        margin-bottom: -5px; /* Adjust the negative margin to match the scaling factor */
        cursor: pointer;
    }
    .certification-logo1 {
      ${tw`object-contain`} /* Keeps the original shape of the image */
      max-height: 100%;  /* Adjust the size as needed */
      max-width: 100%;
    }
    .certification-logo {
      ${tw`object-contain`} /* Keeps the original shape of the image */
      max-height: 70%;  /* Adjust the size as needed */
      max-width: 70%;
    }
  }

  @media (min-width: 768px) {
    /* Apply horizontal alignment only on screens with a minimum width of 768px (big screens) */
    flex-direction: row;
  }
`;

import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img from "../../assets/images/floor.jpg";
import { useEffect} from 'react';
import c1 from "../../assets/images/c1.png";
import c2 from "../../assets/images/c2.png";
import c3 from "../../assets/images/c3.png";
import c4 from "../../assets/images/c4.png";


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
`;


const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;

const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
  
    opacity-40
    relative
    overflow-hidden
  `}
 
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;



const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;

export function LifePT(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Professional Growth and Development</CardTitle>
            <CardHr />
            <CardText>
            At PLASTICUM, we believe that our success is driven by the talent, dedication, and creativity of our team members. If you're passionate about pushing the boundaries of plastic injection molding and are seeking a dynamic and rewarding career, explore our current job openings.
              
            </CardText>
            
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={c2} />
  <BlueBackground/>

</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={c4} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Collaborative Work Environment</CardTitle>
            <CardHr />
            <CardText>
            Our work environment is built on collaboration, open communication, and the belief that diverse perspectives drive innovation. We value teamwork and encourage active participation in brainstorming sessions and idea sharing. Our projects often involve multidisciplinary teams, allowing our employees to interact with experts across various domains.
            </CardText>
            
         
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Employee Benefits</CardTitle>
            <CardHr />
            <CardText>
            At PLASTICUM, we believe that a happy and healthy team is a productive team. We offer a comprehensive range of employee benefits, including competitive compensation packages. Additionally, we provide performance-based incentives and recognition programs to acknowledge and celebrate the hard work and dedication of our employees. We are committed to creating a work environment that supports a fulfilling work-life balance.

            </CardText>
          
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
        <CardImgR src={c1} /> 
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 
<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={c3} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Life at PLASTICUM</CardTitle>
            <CardHr />
            <CardText>
            At PLASTICUM, we view careers as journeys of growth, learning, and fulfillment. We are committed to creating an inclusive and empowering environment where each team member can thrive both professionally and personally. Join us at PLASTICUM and be a part of a vibrant, innovative, and collaborative culture that encourages you to excel and make a difference. Your journey of growth begins here!
            </CardText>
            
         
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
    </>
  );
}

import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img1 from "../../assets/images/IRS/machine tampo.jpg";
import img2 from "../../assets/images/IRS/tampo A.jpg";
import img3 from "../../assets/images/IRS/SOMFY.jpg";
import img4 from "../../assets/images/IRS/MAGASINIER ACCEL.jpg";
import img5 from "../../assets/images/IRS/PALPAGE 3D-2.jpg";
import img6 from "../../assets/images/IRS/MPF A-C.jpg";
import img7 from "../../assets/images/IRS/HOUSING.jpg";import { useEffect} from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-20
    relative
    overflow-hidden
  `}
  background-color:#E6E333;
  background-color:#D68A00;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-60
    relative
    overflow-hidden
  `}
  background-color:#2E6EBD;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground5 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-20
    relative
    overflow-hidden
  `}
  background-color:#DDC001;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-70
    relative
    overflow-hidden
   
  `}
 
  background-color:#739FDB;
`;
const BlueBackgroundL4 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-30
    relative
    overflow-hidden
   
  `}
  background-color:#4B8FC5;
`;
const BlueBackgroundL6 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-50
    relative
    overflow-hidden
   
  `}
  background-color:#8A716D;
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;
export function ContentCardT4 (props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Comprehensive Solutions</CardTitle>
            <CardHr />
            <CardText>
            Imagine a product that begins as a vision, takes shape through precision injection molding with meticulous care. But it doesn't stop there. Our commitment to delivering comprehensive solutions shines when we add the finishing touches.
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img3} />
  <BlueBackground />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img1} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Component Assembly</CardTitle>
            <CardHr />
            <CardText>
            When your project involves multiple parts that need to come together seamlessly, we offer component assembly services. Our skilled technicians ensure that each component is fitted and secured correctly, resulting in a finished product ready for use.
            </CardText>
          </TextContainer>
        </Container2>
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Laser & Pad Printing</CardTitle>
            <CardHr />
            <CardText>
            Our advanced capabilities include laser and pad printing, which allow us to add intricate designs, branding, and labeling to your plastic components. Laser printing offers precision engraving, ensuring durability and clarity of markings. On the other hand, pad printing allows us to transfer detailed graphics onto irregular or contoured surfaces with unmatched precision and vibrancy
            </CardText>
          
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
          <CardImgR src={img2} />
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 

<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img4} />
        <BlueBackgroundL4/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Streamlined Supply Chain</CardTitle>
            <CardHr />
            <CardText>
            Getting your products to their destination on time and in optimal condition is crucial. We offer logistics and shipping solutions to streamline the process, reduce costs, and minimize transit times. Whether your products are destined for local or global markets, we have the expertise to ensure safe and efficient transportation.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Quality Assurance and Testing</CardTitle>
            <CardHr />
            <CardText>
            Quality assurance is at the core of our services. We conduct rigorous inspections and testing to ensure that your products meet your specifications and industry standards. Our testing capabilities encompass a wide range of criteria, from dimensional accuracy to material properties.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img5} />
  <BlueBackground5 />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img6}/>
        <BlueBackgroundL6/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Full Project Lifecycle Support</CardTitle>
            <CardHr />
            <CardText>
            Our commitment to delivering end-to-end solutions goes beyond injection molding. We offer a suite of related services to support your project, from component assembly and packaging to quality assurance and logistics. Our integrated approach streamlines your supply chain, enhances cost control, and ensures hassle-free project management.
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>


    </>
  );
}

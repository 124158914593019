import { GetRfiButton } from "../Buttons";
import { BigTitle, BigTitleBlue, MainTitle } from "../Typography/titles";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import secondimg from "../../assets/images/smart.jpg"
import FirstUniversalCard from "./firstUniversalCard";
import i4 from "../../assets/images/P/i4.png"

import { useState } from "react";
import { Reveal } from "../Reveal";

const CompanyMainCard = ()  =>{


     return(
      <Reveal>
      
      <FirstUniversalCard title="Our Company" text="At PLASTICUM, we are more than just a manufacturer. We are a team of dedicated professionals driven by a passion for excellence in plastic injection molding. Explore our company s history, commitment to sustainability, and career opportunities." imag={i4}/>

      </Reveal>
     )
}
export default CompanyMainCard;
import React, { useState } from 'react';
import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';

import img1 from "../../assets/images/Domotic/somfy yellow.png";
import img2 from "../../assets/images/Domotic/456A8752-2.jpg";
import img3 from "../../assets/images/Domotic/SOMFY.jpg";
import img4 from "../../assets/images/Domotic/456A8762-2.jpg";

import { useEffect } from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #CE9333;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #969BAE;
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;




export function ContentCardIndus2(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
      <div className="lg:flex">
        <Reveal>
          <Card color="blue" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Serving domotic pioneers
                  </CardTitle>
                  <CardHr />
                  <CardText>
                    Smart home devices are all about convenience and user-friendliness. We manufacture components for intuitive user interfaces, such as touch panels, buttons, and displays. These components ensure that homeowners can easily control and monitor various systems within their homes, from lighting to climate control.
                  </CardText>

                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
                <CardImgR src={img1} />
                <BlueBackground className="backgroundColor-969BAE" />

              </ImageWrapper>

            </div>
          </Card>
        </Reveal>
      </div>


      <Reveal>
        <Card color="white">
          <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
            <ImageWrapper2 >
              <CardImg src={img2} />
              <BlueBackgroundL />
            </ImageWrapper2>
          </div>
          <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
            <TextContainer>
              <CardTitle >Sensors and Detectors</CardTitle>
              <CardHr />
              <CardText>
                Safety and security are paramount in smart homes. Our injection-molded sensor housings and enclosures protect delicate sensors and detectors that provide real-time information about environmental conditions, intrusions, and emergencies. These components are designed to blend seamlessly with the home's aesthetics.

              </CardText>


            </TextContainer>
          </Container2>


        </Card>
      </Reveal>

      <div className="lg:flex">
        <Reveal>
          <Card color="blue" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Entertainment and Audio Systems</CardTitle>
                  <CardHr />
                  <CardText>
                    The entertainment experience in smart homes is elevated with high-quality audio systems. Our components for speakers, speaker enclosures, and audio interfaces contribute to immersive audio experiences, blending seamlessly with home décor.

                  </CardText>

                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative">
                <CardImgR src={img3} />
                <BlueBackground3 />
              </ImageWrapper>
            </div>
          </Card>
        </Reveal>
      </div>
      <Reveal>
        <Card color="white">
          <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
            <ImageWrapper2 >
              <CardImg src={img4} />
              <BlueBackgroundL />
            </ImageWrapper2>
          </div>
          <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
            <TextContainer>
              <CardTitle >Connectivity Hubs</CardTitle>
              <CardHr />
              <CardText>
                Central to smart homes are connectivity hubs. We create housings and enclosures for routers, smart home controllers, and connectivity devices that ensure seamless communication between various smart devices.
              </CardText>


            </TextContainer>
          </Container2>


        </Card>
      </Reveal>

    </>
  );
}

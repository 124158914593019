import React from 'react';
import { BigTitle, BigTitleBlue } from '../Typography/titles';
import styled from 'styled-components/macro'; 
import { ReadMoreButton } from '../Buttons';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  height: 750px;
  position: relative; 
  width:100%;
`;

const BlueBackground = styled.div`
  ${tw`
    absolute
    top-4  
    left-[44rem]
    rounded-tl-[30rem] 
    rounded-bl-[30rem] 
    h-[500px] 
    w-[700px]
    bg-blue-500
    opacity-40
    transition-all
    duration-300
    ml-4
    overflow-hidden
  `}
  z-index: -1; 
`;
const BlueBackgroundTwo = styled.div`
  ${tw`
    absolute
    top-[516px]  
    right-[1rem]
    h-[130px] w-[300px]
    bg-blue-500
    opacity-40
    transition-all
    duration-300
    ml-4
    overflow-hidden
  `}
  z-index: -1; 
`;
const FirstUniversalCardBlog = ({title="",text=""}) => {
  const handleButtonClick =()=>{

  }
  return (
    <Container>
      <div className='flex flex-row lg:pl-20 ' style={{ zIndex: 1 }}>
        <BigTitleBlue titleText={title} text={text}/>
        <div  style={{ position: 'absolute', top: '350px', left: "120px", right: 0, bottom: 0 }}> <Link to="/blog-all">
      <button className="border rounded-[10px] bg-secondary-blue w-[200px] h-12 mt-10 text-white font-bold flex items-center justify-center md:w-[150px] md:h-10">
      More Articles
    </button> </Link></div> 
      </div>
      <BlueBackground />
      <div className=' top-shape flex flex-row bg-bg-blue  rounded-tl-[30rem] rounded-bl-[30rem] h-[500px] w-[800px] relative'>
      <BlueBackgroundTwo />
        <div className='bottom-shape absolute  bg-bg-blue h-[130px] w-[300px] right-0 bottom-0 top-[500px]'/>
      </div>
      
      
    </Container>
  );
};

export default FirstUniversalCardBlog;

import  { useState, useEffect, useRef } from 'react';
import { SecondTitle } from '../Typography/titles';
import { MainButtonLeft, MainButtonRight, ReadMoreButton, ZoomButton } from '../Buttons/index';
import styled, { css } from 'styled-components';



const ZoomCard = () => {
  
  const [selectedButton, setSelectedButton] = useState('Text 1'); 
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (buttonText: any) => {
    setSelectedButton(buttonText);
    const buttonIndex = ['Text 1', 'Text 2', 'Text 3', 'Text 4', 'Text 5'].indexOf(buttonText);
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${buttonIndex * 100}%)`;
    }
  };
  useEffect(() => {
    if (selectedButton && sliderRef.current) {
      const buttonIndex = ['Text 1', 'Text 2', 'Text 3', 'Text 4', 'Text 5'].indexOf(selectedButton);
      sliderRef.current.style.transform = `translateX(-${buttonIndex * 100}%)`;
    }
  }, [selectedButton]);
  
  
  const handleClick = () => {
  
  };

  return (
    <div className="flex h-[42rem]">
<div className="w-full h-full opacity-100 transition-opacity duration-500 grid grid-cols-3">
<div className="col-span-3 md:col-span-2 lg:col-span-1">
  <div className="bg-secondary-blue h-[61px] items-center justify-center text-center">
    <SecondTitle titleText="Title" />
  </div>

  <div className="bg-main-blue h-auto md:h-[90.5%] top-10 space-x-10 space-y-15 pl-16 md:pl-18 md:flex md:flex-col w-full">
  <p className="text-white text-[36px] font-bold justify pl-7 pt-7 pb-10">
    Lorem ipsum dolor adipiscing elit, sed do elit
  </p>


  {/* Pour les écrans de taille moyenne et petites (entre 1030px et 100px) */}
  <div className="hidden md:flex flex-row space-x-4 pb-4 pl-4 sm:hidden overflow-x-auto overflow-y-hidden mx-auto ml-10 mr-10">
  <ZoomButton
    buttonText="Co-design"
    isSelected={selectedButton === 'Text 1'}
    onClick={() => handleButtonClick('Text 1')}
    customClass="w-[100px]"
  />
  <ZoomButton
    buttonText="Tooling"
    isSelected={selectedButton === 'Text 2'}
    onClick={() => handleButtonClick('Text 2')}
    customClass="w-[100px]"
  />
  <ZoomButton
    buttonText="Injection"
    isSelected={selectedButton === 'Text 3'}
    onClick={() => handleButtonClick('Text 3')}
    customClass="w-[100px]"
  />
  <ZoomButton
    buttonText="Services"
    isSelected={selectedButton === 'Text 4'}
    onClick={() => handleButtonClick('Text 4')}
    customClass="w-[100px]"
  />
  <ZoomButton
    buttonText="Tool Management"
    isSelected={selectedButton === 'Text 5'}
    onClick={() => handleButtonClick('Text 5')}
    customClass="w-[200px]"
  />
</div>


  {/* Pour les grands écrans (taille md et plus) */}
  <div className="hidden lg:flex flex-col space-y-8 w-full lg:w-[200px]">
    <ZoomButton
      buttonText="Co-design"
      isSelected={selectedButton === 'Text 1'}
      onClick={() => handleButtonClick('Text 1')}
    />
    <ZoomButton
      buttonText="Tooling"
      isSelected={selectedButton === 'Text 2'}
      onClick={() => handleButtonClick('Text 2')}
    />
    <ZoomButton
      buttonText="Injection"
      isSelected={selectedButton === 'Text 3'}
      onClick={() => handleButtonClick('Text 3')}
    />
    <ZoomButton
      buttonText="Services"
      isSelected={selectedButton === 'Text 4'}
      onClick={() => handleButtonClick('Text 4')}
    />
    <ZoomButton
      buttonText="Tool Management"
      isSelected={selectedButton === 'Text 5'}
      onClick={() => handleButtonClick('Text 5')}
    />
  </div>
</div>
</div>

  <div className="col-span-3 md:col-span-2 lg:col-span-2 w-full">
        <div className=" items-center justify-center text-center "/>
        <div className=" h-full justify-start px-10 py-7 overflow-hidden">
          <div ref={sliderRef} className="slider-content">
          <div className="slider-item">
  {selectedButton === 'Text 1' && (
    <div className="image-container">
      
       <img
        className=" pic mt-28 h-[25rem] w-[28rem] rounded-tl-[120px] rounded-br-[120px] z-10"
        src="https://media.istockphoto.com/id/1322277517/fr/photo/herbe-sauvage-dans-les-montagnes-au-coucher-du-soleil.jpg?s=612x612&w=0&k=20&c=tQ19uZQLlIFy8J6QWMyOL6lPt3pdSHBSDFHoXr1K_g0="
        alt="First Image"
      />
      <div className="pic2 absolute top-24 left-[2rem] rounded-tl-[120px] rounded-br-[120px] w-[26rem] h-[25rem] bg-blue-500 opacity-40 transition-all duration-300 ml-4 mt-7" />
      
      <div className="half text">
      <div className='wi2'><SecondTitle titleText="Title 1" /></div>
        <p className="text font-[18px] justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="ml-[2rem] mt-[2rem]">
          <MainButtonRight link="" />
        </div>
      </div>
   </div>
  )}
</div>


<div className="slider-item">
              {selectedButton === 'Text 2' && 
              <div className="image-container">
              
              <div className='w-[400px]  grid-col-2 mt-50'>
                <div className='art2 mt-20 -mb-20'><SecondTitle titleText='Title 2'  /></div>
                <p className='text2 font-[18px] justify sm:mt-20 '>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className='  pl-6 pt-8 '>
                  <MainButtonRight link="" />
                </div>
              </div>
              <img
        className=" pic mt-28 h-[25rem] w-[28rem] rounded-tl-[120px] rounded-br-[120px] z-10"
        src="https://media.istockphoto.com/id/1322277517/fr/photo/herbe-sauvage-dans-les-montagnes-au-coucher-du-soleil.jpg?s=612x612&w=0&k=20&c=tQ19uZQLlIFy8J6QWMyOL6lPt3pdSHBSDFHoXr1K_g0="
        alt="First Image"
      />
      <div className="pic2 absolute top-24 left-[420px] rounded-tl-[120px] rounded-br-[120px] w-[26rem] h-[25rem] bg-blue-500 opacity-40 transition-all duration-300 ml-4 mt-7" />
            </div>
              }
            </div>
            <div className="slider-item">
  {selectedButton === 'Text 3' && (
    <div className="image-container">
      
       <img
        className=" pic mt-28 h-[25rem] w-[28rem] rounded-tl-[120px] rounded-br-[120px] z-10"
        src="https://media.istockphoto.com/id/1322277517/fr/photo/herbe-sauvage-dans-les-montagnes-au-coucher-du-soleil.jpg?s=612x612&w=0&k=20&c=tQ19uZQLlIFy8J6QWMyOL6lPt3pdSHBSDFHoXr1K_g0="
        alt="First Image"
      />
      <div className="pic2 absolute top-24 left-[2rem] rounded-tl-[120px] rounded-br-[120px] w-[26rem] h-[25rem] bg-blue-500 opacity-40 transition-all duration-300 ml-4 mt-7" />
      
      <div className="half text">
      <div className='wi2'><SecondTitle titleText="Title 3" /></div>
        <p className="text font-[18px] justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="ml-[2rem] mt-[2rem]">
          <MainButtonRight link="" />
        </div>
      </div>
   </div>
  )}
</div>
          
<div className="slider-item">
              {selectedButton === 'Text 4' && 
              <div className="image-container">
              
              <div className='w-[400px]  grid-col-2 mt-50'>
                <div className='art2 mt-20 -mb-20'><SecondTitle titleText='Title 4'  /></div>
                <p className='text2 font-[18px] justify sm:mt-20 '>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div className='  pl-6 pt-8 '>
                  <MainButtonRight link="" />
                </div>
              </div>
              <img
        className=" pic mt-28 h-[25rem] w-[28rem] rounded-tl-[120px] rounded-br-[120px] z-10"
        src="https://media.istockphoto.com/id/1322277517/fr/photo/herbe-sauvage-dans-les-montagnes-au-coucher-du-soleil.jpg?s=612x612&w=0&k=20&c=tQ19uZQLlIFy8J6QWMyOL6lPt3pdSHBSDFHoXr1K_g0="
        alt="First Image"
      />
     <div className="pic2 absolute top-24 left-[420px] rounded-tl-[120px] rounded-br-[120px] w-[26rem] h-[25rem] bg-blue-500 opacity-40 transition-all duration-300 ml-4 mt-7" />

<style>
  {`
    @media (max-width: 1300px) {
      .pic2 {
        display: none;
      }
    }
  `}
</style>

            </div>
              }
            </div>
            <div className="slider-item">
  {selectedButton === 'Text 5' && (
    <div className="image-container">
      
       <img
        className=" pic mt-28 h-[25rem] w-[28rem] rounded-tl-[120px] rounded-br-[120px] z-10"
        src="https://media.istockphoto.com/id/1322277517/fr/photo/herbe-sauvage-dans-les-montagnes-au-coucher-du-soleil.jpg?s=612x612&w=0&k=20&c=tQ19uZQLlIFy8J6QWMyOL6lPt3pdSHBSDFHoXr1K_g0="
        alt="First Image"
      />
      <div className="pic2 absolute top-24 left-[2rem] rounded-tl-[120px] rounded-br-[120px] w-[26rem] h-[25rem] bg-blue-500 opacity-40 transition-all duration-300 ml-4 mt-7" />
      
      <div className="half text">
      <div className='wi2'><SecondTitle titleText="Title 5" /></div>
        <p className="text font-[18px] justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit inLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="ml-[2rem] mt-[2rem]">
          <MainButtonRight link="" />
        </div>
      </div>
   </div>
  )}
</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ZoomCard;

import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img from "../../assets/images/Footer/footer6.jpg";
import { useEffect} from 'react';
import x from "../../assets/images/x.png";
import x1 from "../../assets/images/x1.png";
import x3 from "../../assets/images/x3.png";
import x4 from "../../assets/images/x4.png";


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:750px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #01479B;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #969BAE;
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;

export function ContentCardCompany(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
      <Reveal>
        <Card color="blue">
        <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
            <ImageWrapper2>
              <CardImg src={x3} />
              <BlueBackgroundL />
            </ImageWrapper2>
          </div>
          <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
            <TextContainer>
              <CardTitle >Sustainability</CardTitle>
              <CardHr />
              <CardText>
              PLASTICUM is dedicated to sustainability, focusing on responsible manufacturing practices. Their initiatives cover material selection and energy-efficient processes to reduce their environmental impact while maintaining high-quality outputs. In addition to investing in electrical machines to reduce energy consumption, we are implementing :
<br></br>
- Smart Factory Solution: which enables us to monitor energy consumption by machine and by part in real time.
<br></br>
- Solar energy installation with a production target of 470KWp (approx. 610MWh/year)


              </CardText>



            </TextContainer>
          </Container2>


        </Card>
      </Reveal>
      <div className="lg:flex">
        <Reveal>
          <Card color="white" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Technological Innovation</CardTitle>
                  <CardHr />
                  <CardText>
                    PLASTICUM is a technological powerhouse, at the forefront of cutting-edge advancements in plastic injection molding. We continuously invest in research and development, embracing emerging technologies, such as AI, IoT, and automation, to enhance our manufacturing processes. Our dedicated team of engineers and technologists push boundaries, bringing forth innovative solutions that optimize efficiency, precision, and sustainability.

                  </CardText>

                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
                <CardImgR src={x} />
                <BlueBackground className="backgroundColor-969BAE" />

              </ImageWrapper>

            </div>
          </Card>
        </Reveal>
      </div>


      <Reveal>
        <Card color="blue">
        <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
            <ImageWrapper2>
              <CardImg src={x1} />
              <BlueBackgroundL />
            </ImageWrapper2>
          </div>
          <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
            <TextContainer>
              <CardTitle >Quality Assurance</CardTitle>
              <CardHr />
              <CardText>
                Quality is the cornerstone of everything we do at PLASTICUM. We adhere to the highest industry standards, implementing rigorous quality control measures at every stage of production. From material selection to the final product, we ensure that our components meet or exceed the expectations of our clients. Our commitment to quality is reflected in our certifications and the trust our clients place in us.
              </CardText>



            </TextContainer>
          </Container2>


        </Card>
      </Reveal>

      <div className="lg:flex">
        <Reveal>
          <Card color="white" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Global Reach and Collaborations</CardTitle>
                  <CardHr />
                  <CardText>
                    PLASTICUM's reach extends beyond borders. We pride ourselves on establishing fruitful collaborations and partnerships with organizations across the globe. These collaborations enable us to merge diverse perspectives, foster innovation, and create a global impact. We believe in the power of collaboration to drive progress and mutually beneficial growth.

                  </CardText>

                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative">
                <CardImgR src={x4} />
                <BlueBackground3 />
              </ImageWrapper>
            </div>
          </Card>
        </Reveal>
      </div>

    </>
  );
}

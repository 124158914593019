import React, { useState, useEffect } from 'react';
import { LittleTitle } from '../Typography/titles';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import imprimer from "../../assets/images/imprimer.jpg";
import smart from "../../assets/images/smart.jpg"
import lean from "../../assets/images/lean.jpg"
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import s1 from "../../assets/images/s1.png"
import s2 from "../../assets/images/s2.png"
import s3 from "../../assets/images/s3.png"
import s4 from "../../assets/images/s4.png"
import s5 from "../../assets/images/s5.png"
import s6 from "../../assets/images/s6.png"
import img3 from "../../assets/images/Domotic/SOMFY.jpg";


interface CardData {
  name: string;
  text: string;
  link: string;
  imageUrl: string;
}

const VerticalSlider: React.FC = () => {

  const cards: CardData[] = [
    {
      name: 'Co-design & Product Development',
      text: 'Partnering to turn concepts into optimized, cost-effective designs.',
      link: 'co-design-product-development',
      imageUrl: s1
    },
    {
      name: 'Plastic Injection Moulding',
      text: 'Setting the standard in quality and speed for plastic moulding.',
      link: 'plastic-injection-molding',
      imageUrl: s4
    },
    {
      name: 'Automotive Industry',
      text: 'High-quality solutions for automotive plastic components.',
      link: 'automotive',
      imageUrl: s5
    },
    {
      name: 'Chart your Career',
      text: 'Join us, where skills are celebrated and career ambitions nurtured.',
      link: 'careers',
      imageUrl: s3
    },
    {
      name: 'Domotic Industry',
      text: 'Supporting smarter, efficient homes in the domotic industry.',
      link: 'domotic',
      imageUrl: img3
    },
    {
      name: 'Welcome to Plasticum',
      text: 'More than a manufacturer - innovating for efficiency.',
      link: 'company',
      imageUrl: s2
    },
  ];


  const [activeSlide, setActiveSlide] = useState(0);

  const prevIndex = (activeSlide - 1 + cards.length) % cards.length;
  const nextIndex = (activeSlide + 1) % cards.length;

  const nextSlide = () => {
    setActiveSlide(nextIndex);
  };

  const prevSlide = () => {
    setActiveSlide(prevIndex);
  };

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(transitionInterval);
  }, [nextSlide]);;

  return (

    <div className='slider-container'>
      <div className='pt-56' style={{ marginLeft: window.innerWidth >= 768 ? '15px' : '0' }}>
        <div className="wrapper">

          <div className="outer">
            {cards.map((card, index) => (
              <a
                key={index}
                href={card.link}
                className={`card ${activeSlide === index ? 'active' : ''} ${prevIndex === index ? 'prev' : ''} ${nextIndex === index ? 'next' : ''}`}
                style={{ '--delay': index } as React.CSSProperties}
              >
                <div className="content">
                  <div className="details ">
                    <LittleTitle titleText={card.name} text={card.text} lineColor='bg-secondary-gray w-32 ' />


                  </div>
                  <div className="img">
                    <img src={card.imageUrl} alt={card.name} className="" />
                  </div>
                </div>
              </a>
            ))}
          </div>

        </div>
      </div>
    </div>
  );



};

export default VerticalSlider;

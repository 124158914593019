import React ,{useEffect,useState} from 'react'
import CaseStudiesCard from "../../components/Cards/caseStudiesCard";
import { ContentCardUni } from "../../components/Cards/contentCardUni";
import { ContentCardIndus } from "../../components/Cards/contentCardIndus";
import { ContentCardIndus2 } from "../../components/Cards/contentCardIndus2";
import { ContentCardIndus3 } from "../../components/Cards/contentCardIndus3";
import Nav from "../../components/nav";
import NewsCard from "../../components/Cards/newsCard";
import FirstUniversalCard from "../../components/Cards/firstUniversalCard";
import ZoomCard2 from "../../components/Cards/zoomCard2";
import { MainTitle } from "../../components/Typography/titles";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import BackToTopButton from '../../components/Buttons/backToTop';
import SecondUniversalCard from '../../components/Cards/secondUniversalCard';
import SecondUniversalCardi1 from '../../components/Cards/secondUniversalCardI1';
import SecondUniversalCardi2 from '../../components/Cards/secondUniversalCardI2';
import SecondUniversalCardi3 from '../../components/Cards/secondUniversalCardI3';
import VideoCard from '../../components/Cards/videoCard';
import CatalogCard from '../../components/Cards/catalog';
import i6 from "../../assets/images/P/i6.png"
import i13 from "../../assets/images/P/i13.png"
import i5 from "../../assets/images/P/i5.png"
import footerImage from "../../assets/images/Footer/footer6.jpg";


export const IndustriesMainPage =()=>{
    const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return(
    <div className="">
      <ContactNavBar />
        <Nav />
        <FirstUniversalCard title="Meeting the Needs of Diverse Industries" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"/>
        <ZoomCard2 />
        <div className='our'>
        <CatalogCard />
        </div>
        
        
         
        <BackToTopButton show={showBackToTop} />  
          <Footer backgroundImage={`url(${footerImage})`}/>

    </div>
    )
}

/* Second Component*/

export const IndustriesSecondPage =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
 <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Automotive Industry" text="The automotive industry demands precision, durability, and innovation. PLASTICUM is a trusted partner in delivering high-quality plastic injection molding solutions for various automotive components. Whether it's interior trim, under-the-hood parts, lighting components, or electronic enclosures, our commitment to innovation and quality ensures that we meet the stringent requirements of the automotive sector." imag={i6}/>
      <div className='bg-white'>
        <ContentCardIndus />

        <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
      </div>
        <Footer backgroundImage={`url(${footerImage})`}/>

  
      </div>
  )
}



export const IndustriesSecondPage2 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
<div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Domotic Industry" text="Our commitment to the Domotic industry extends beyond providing components; we strive to be a catalyst for innovation. Our injection-molded parts are not just functional; they are designed to enhance the aesthetics and integration of smart home devices, seamlessly blending technology with the comfort and style of modern living. PLASTICUM is dedicated to supporting the Domotic industry in its mission to create homes that are smarter, more efficient, and more enjoyable for homeowners worldwide." imag={i13}/>
      <div className='bg-white'>
      <ContentCardIndus2 />
      <CatalogCard />
      
       
      <BackToTopButton show={showBackToTop} />  
      </div>
        <Footer backgroundImage={`url(${footerImage})`}/>

  
      </div>
  )
}


export const IndustriesSecondPage3 =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
 <div>
      <ContactNavBar />
      <Nav />
      <FirstUniversalCard title="Other Industries" text="At PLASTICUM, we understand the critical role that tooling plays in the injection molding process. Our integrated tool management approach ensures that your molds are maintained, optimized, and ready for action at all times." imag={i5}/>
      <div className='bg-white'>
      <ContentCardIndus3 />
      <CatalogCard />
      
       

      <BackToTopButton show={showBackToTop} />  
      </div>
        <Footer backgroundImage={`url(${footerImage})`}/>

  
  </div>
  )
}
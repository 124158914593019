import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import moule from "../../assets/images/Moule.jpg";
import acoudoir from "../../assets/images/acoudoir.jpg";
import floor from "../../assets/images/floor.jpg";
import lean from "../../assets/images/lean.jpg";import { useEffect} from 'react';
import c4 from "../../assets/images/c3.png";


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
  
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  `};
  height:550px;
  ${({ color }) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}

  @media (max-width: 1100px) {
    ${css`
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: column; // Changed to column for mobile view
      padding-bottom:0;
      margin:0;
      padding-top:0;
      height:1100px
   // Adjust height based on content
    `}
  }
`;


const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    order: 1; // Title and content first on mobile
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    order: 2; // Image second on mobile
    height:450px;
  }
`;

const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #969BAE;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[28rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color: #01479B;
  @media (max-width: 600px) {
    ${tw`
      w-[22rem]
    `}
  }
`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
   
  `}
  background-color: #969BAE;
`;

const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height:450px;
    
    `}
  }
`;


export function ContentCard(props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
      <Reveal>
        <Card style={{ marginTop: '80px' }} color="white">
          <ImageWrapper2>
            <CardImg src={c4} />
            <BlueBackgroundL />
          </ImageWrapper2>
          <Container2>
            <TextContainer>
              <CardTitle>History</CardTitle>
              <CardHr />
              <CardText>
              Founded in 2010, PLASTICUM embarked on transforming the plastics industry with innovative technologies and methods, revolutionizing plastic injection molding. Our commitment to precision, sustainability, and adaptability has made us an industry leader, shaping various sectors with our customer-centric approach.</CardText>
              <MainButtonLeft link="company" />
            </TextContainer>
          </Container2>
        </Card>
      </Reveal>

      <div className="lg:flex">
        <Reveal>
          <Card color="blue" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Bring life to your ideas</CardTitle>
                  <CardHr />
                  <CardText>
                    To support its customers' development and meet their expectations, PLASTICUM has developed the "Turnkey Solution" concept, enabling it to evolve into a complete solutions integrator.

                  </CardText>
                  <MainButtonRight link="co-design-product-development" />
                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
                <CardImgR src={moule} />
                <BlueBackground className="backgroundColor-969BAE" />

              </ImageWrapper>

            </div>
          </Card>
        </Reveal>
      </div>

      <Reveal>
        <Card style={{ marginTop: '80px' }} color="white">
          <ImageWrapper2>
            <CardImg src={acoudoir} />
            <BlueBackgroundL />
          </ImageWrapper2>
          <Container2>
            <TextContainer>
              <CardTitle>Key participant in the automotive industry</CardTitle>
              <CardHr />
              <CardText>
              At PLASTICUM, we take pride in our unwavering dedication to adhering to the highest International Automotive Task Force (IATF) standards. Our state-of-the-art manufacturing processes and  team of skilled professionals ensure that every product we create meets and exceeds these rigorous standards
              </CardText>
              <MainButtonLeft link="company" />
            </TextContainer>
          </Container2>
        </Card>
      </Reveal>



      <div className="lg:flex">
        <Reveal>
          <Card color="blue" className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:mb-0">
              <Container className="mb-8 lg:mb-0">
                <TextContainer>
                  <CardTitle>Serving a higher purpose</CardTitle>
                  <CardHr />
                  <CardText>
                    At PLASTICUM, we view careers as journeys of growth, learning, and fulfillment. We are committed to creating an inclusive and empowering environment where each team member can thrive both professionally and personally. Join us at PLASTICUM and be a part of a vibrant, innovative, and collaborative culture that encourages you to excel and make a difference. Your journey of growth begins here!
                  </CardText>
                  <MainButtonRight link="company" />
                </TextContainer>
              </Container>
            </div>
            <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
              <ImageWrapper className="lg:relative">
                <CardImgR src={floor} />
                <BlueBackground3 />
              </ImageWrapper>
            </div>
          </Card>
        </Reveal>
      </div>

    </>
  );
}

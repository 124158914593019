import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

type BackToTopButtonProps = {
  show: boolean;
};

const BackToTopButtonContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
`;

const BackToTopButtonStyled = styled.button<BackToTopButtonProps>`
  padding: 1rem;
  border-radius: 50%;
  background-color: #243372;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;

  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

const BackToTopButton: React.FC<BackToTopButtonProps> = ({ show }) => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <BackToTopButtonContainer>
      <BackToTopButtonStyled show={show} onClick={handleBackToTop}>
        <FontAwesomeIcon icon={faChevronUp} size="lg" />
      </BackToTopButtonStyled>
    </BackToTopButtonContainer>
  );
};

export default BackToTopButton;

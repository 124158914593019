import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './app/containers/HomePage';
import { TurnKeySolutionsMainPage, TurnKeySolutionsSecondPage,TurnKeySolutionsSecondPage2,TurnKeySolutionsSecondPage3,TurnKeySolutionsSecondPage4,TurnKeySolutionsSecondPage5} from './app/containers/TurnkeySolutions'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import VerticalSlider from './app/components/Cards/verticalSlider';
import VerticalCarousel from './app/components/Cards/vericalCarousel';
import { IndustriesMainPage, IndustriesSecondPage,IndustriesSecondPage2,IndustriesSecondPage3 } from './app/containers/Industries';
import { BlogMainPage, News, Events , Press ,All } from './app/containers/Blog';
import ContactCard from './app/components/Cards/contact';
import ContactUs from './app/containers/ContactUs';
import Careers from './app/containers/Careers';
import Company from './app/containers/Company';
import Upload from './app/containers/upload';
import Home from './app/containers/images';
import {Results} from './app/components/Cards/results';
import { CaseStudies } from './app/containers/Ressources';
import BlogMainResults from './app/components/Cards/blogMainReseults';
import './i18n';

const App = () => {
  return (
    <Router>
  <Routes>
    
    <Route path="/" element={<HomePage/>} />
     {/* Turnkey Solutions Routes */}
    <Route path='/co-design-product-development' element={<TurnKeySolutionsSecondPage />} />
    <Route path='/tooling-development-manufacturing' element={<TurnKeySolutionsSecondPage2 />} />
    <Route path='/plastic-injection-molding' element={<TurnKeySolutionsSecondPage3 />} />
    <Route path='/injection-related-services' element={<TurnKeySolutionsSecondPage4 />} />
    <Route path='/integrated-tool-management' element={<TurnKeySolutionsSecondPage5 />} />
    {/* Industries Routes */}
    <Route path="/automotive" element={<IndustriesSecondPage/>} />
    <Route path="/domotic" element={<IndustriesSecondPage2/>} />
    <Route path="/other-industries" element={<IndustriesSecondPage3/>} />
    {/* Blog Routes */}
    <Route path="/blog-one" element={<News/>} />
    <Route path="/blog-two" element={<Events/>} />
    <Route path="/blog-three" element={<Press/>} />
    <Route path="/blog-all" element={<All/>} />

    <Route path="/contact-us" element={<ContactUs/>} />

    <Route path="/careers" element={<Careers />} />
    
    <Route path="/company" element={<Company />} />
   
    <Route path="/upload" element={<Upload />} />
    <Route path="/images" element={<Home/>}/>

<Route path="/results" element={<Results/>} />
<Route path="/results/tag/:tag" element={<BlogMainResults />} />


  </Routes>
</Router>
  );
};

export default App;

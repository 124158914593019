import React, { ChangeEvent, useState } from "react";
import { ContactTableComponent, EventsTable } from "../table";
import { MainTitle } from "../Typography/titles";
import styled, { css } from 'styled-components';
import FirstUniversalCard from "./firstUniversalCard";
import i1 from "../../assets/images/P/i1.png"

const ContactCardWrapper = styled.div`
  width: 900px;
  margin: 0 auto;


  @media (max-width: 1000px) {
    width: 100%;
    padding: 2rem;
    justify: centre

    
    input,
    textarea,
    select {
      width: 400px; 
      margin-bottom: 1rem; 
    }

  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 1rem;

    
    input,
    textarea,
    select {
      width: 300px; 
      margin-bottom: 1rem; 
    }

    button {
      width: 120px; 
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left:-420px
    }
  }
`;


const ContactCard: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    position: "",
    email: "",
    phoneNumber: "",
    countryCode: "+216",
    message: "",
    companyName: "",
    Motif: "",
    file: null as File | null,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData({
        ...formData,
        file: e.target.files[0],
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const phoneNumberPattern = /^[0-9]+$/;

      if (!emailPattern.test(formData.email)) {
        alert("Please enter a valid email address.");
        return;
      }

      if (!phoneNumberPattern.test(formData.phoneNumber)) {
        alert("Please enter a valid phone number.");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('firstName', formData.firstName);
      formDataToSend.append('lastName', formData.lastName);
      formDataToSend.append('position', formData.position);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phoneNumber', formData.phoneNumber);
      formDataToSend.append('countryCode', formData.countryCode);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('companyName', formData.companyName);
      formDataToSend.append('Motif', formData.Motif);
      if (formData.file) {
        formDataToSend.append('file', formData.file);
      }

      const response = await fetch('https://plasticum.tn/api/contact', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert('Your request has been received, and we will contact you soon.');
        setFormData({
          firstName: "",
          lastName: "",
          position: "",
          email: "",
          phoneNumber: "",
          countryCode: "+216",
          message: "",
          companyName: "",
          Motif: "",
          file: null,
        });
      } else {
        alert('An error occurred while processing your request. Please try again.');
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  return (
    <>
          <FirstUniversalCard title="Contact" text="At PLASTICUM, we pride ourselves on being much more than a mere manufacturing entity. We are a cohesive team of devoted professionals, united by an unwavering passion for achieving the pinnacle of quality in plastic injection molding." imag={i1}/>

     <MainTitle titleText="Contact Our Team" text="Reach out to us and let us discuss your needs and projects"/>
      <ContactTableComponent />
   {/* <MainTitle titleText="Contact us" text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean"/>
    <div className="w-[900px] mx-auto">
      <div className="py-8 px-4">
      <ContactCardWrapper>

      <form encType="multipart/form-data">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">First Name</span>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              required
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Last Name</span>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Company Name</span>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Position</span>
            <input
              type="text"
              name="position"
              value={formData.position}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
            />
            </label>
          <label className="block">
            <span className="text-gray-700">Email</span>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              required
            />
  </label>
<label className="block">
            <span className="text-gray-700">Phone Number</span>
            <div className="mt-1 relative rounded-md shadow-sm">
            <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                className="absolute inset-y-0 left-50 px-2 py-2 bg-gray-100 border-transparent rounded-md  focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              >
                <option value="+26">+216 (TN)</option>
                <option value="+44">+44 (UK)</option>
              </select>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className=" pl-36 p-2 pr-10 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
                required
              />
            </div>
          </label>


          <label className="block">
            <span className="text-gray-700">Motif</span>
            <input
              type="text"
              name="Motif"
              value={formData.Motif}
              onChange={handleChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              required />
          </label>


          <label className="block">
            <span className="text-gray-700">Message</span>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleTextAreaChange}
              className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
              rows={4}
              required
            />
          </label>



          <label className="block">
    <span className="text-gray-700">File</span>
    <input
      type="file"
      name="file"
      onChange={handleFileChange}
      className="mt-1 p-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
    />
  </label>
  </div>
      </form>


        <div className="mt-8 flex justify-center">
  <button
    type="submit"
    className="w-[10rem] py-2 px-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-secondary-blue hover:bg-main-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue"
    onClick={handleSubmit}
  >
    Submit
  </button>


        </div>
  </ContactCardWrapper>
      </div>
    </div>*/}


    <EventsTable />
    </>
  );
};

export default ContactCard;

import React ,{useEffect,useState} from 'react'

import BackToTopButton from "../../components/Buttons/backToTop";
import BigLogoCard from "../../components/Cards/bigLogoCard";
import CaseStudiesCard from "../../components/Cards/caseStudiesCard";
import CertificationsCard from "../../components/Cards/certifications";
import { ContentCard } from "../../components/Cards/contentCard";
import {MeetFamilyCard} from "../../components/Cards/familyCard";
import MainHomeCard from "../../components/Cards/mainHomeCard";
import Nav from "../../components/nav";
import NewsCard from "../../components/Cards/newsCard";
import PartnersCard from "../../components/Cards/partnersCard";
import TestimonialsCard from "../../components/Cards/testimonials";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import PartnerCarousel from "../../components/slider";
import Carroussel from "../../components/Cards/Carroussel";
import CatalogCard from '../../components/Cards/catalog';
import footerImage from "../../assets/images/Footer/footer6.jpg";
import CallCard from "../../components/Cards/callCard";

const HomePage =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
  };
    return(
        <div style={{overflow:"visible"}} className="">
        <ContactNavBar />
      <Nav />
      
      <MainHomeCard />
      <ContentCard />
      <Carroussel/>
      
      <CatalogCard />
      
      <MeetFamilyCard/>
      <PartnersCard />
      <CertificationsCard />
      <CallCard/>
      <BackToTopButton show={showBackToTop} />
      
  <Footer backgroundImage={`url(${footerImage})`}/>


        </div>
    )
}
export default HomePage;
import React ,{useEffect,useState} from 'react'
import { ContentCardTwo } from "../../components/Cards/contentCardtwo";
import Nav from "../../components/nav";
import NewsCard from "../../components/Cards/newsCard";
import FirstUniversalCardBlog from "../../components/Cards/FirstUniversalCardBlog";
import { ContactNavBar } from "../../components/contactNavBar";
import { Footer } from "../../components/footer";
import BackToTopButton from '../../components/Buttons/backToTop';
import { ContentCard } from '../../components/Cards/ContentCardd';
import BlogMainCard from '../../components/Cards/blogMain';
import News1 from '../../components/Cards/blogMain1';
import Events1 from '../../components/Cards/blogMain2';
import Press1 from '../../components/Cards/blogMain3';
import footerImage from "../../assets/images/Footer/footer6.jpg";

import { Link } from 'react-router-dom';
export const BlogMainPage =()=>{
    const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return(
    <div className="">
        <ContactNavBar />
        <Nav />
        <div>
        <FirstUniversalCardBlog title='Blog' text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"/>
      
    </div>
      
        <ContentCard />
        <BackToTopButton show={showBackToTop} />  
          <Footer backgroundImage={`url(${footerImage})`}/>

    </div>
    )
}

/* hethy all */
export const All =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
 <div>
      <ContactNavBar />
      <Nav />
      <BlogMainCard />
     
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
      </div>
  )
}

/* News*/

export const News =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <News1 />
     
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

      </div>
  )
}

/* Events*/

export const Events =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <Events1/>
    
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

  
      </div>
  )
}

/* Press*/

export const Press =()=>{
  const [showBackToTop, setShowBackToTop] = useState(false);

const handleScroll = () => {
  if (window.scrollY > 300) {
    setShowBackToTop(true);
  } else {
    setShowBackToTop(false);
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
  return(
  <div>
      <ContactNavBar />
      <Nav />
      <Press1 />
  
      <BackToTopButton show={showBackToTop} />  
        <Footer backgroundImage={`url(${footerImage})`}/>

      </div>
  )
}

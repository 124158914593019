import React, { useEffect, useState } from 'react';
import { Reveal } from "../Reveal";
import { MainTitle } from "../Typography/titles";
import img from "../../assets/images/contactB.jpg";

import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { GetRfiButton } from '../Buttons';
const useScrollToTopNavigate = () => {
  const navigate = useNavigate();

  return (path: To) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
};
const CallCard = () => {

  const nav = useScrollToTopNavigate();
  const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.style.backgroundColor = 'white';
    event.currentTarget.style.color = '#2d9dd7';
  };

  const handleMouseOut = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.style.backgroundColor = '#2d9dd7';
    event.currentTarget.style.color = 'white';
  };

  return (
    <Reveal>
      <div className="relative mt-0 bg-cover bg-center bg-no-repeat">
        {/* Background Image with Filter */}
        <div style={{
          backgroundImage: `url(${img})`,
          filter: 'brightness(0.5) blur(2px)',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1
        }}>
        </div>
        <div className="w-full   mx-auto p-8 flex flex-col md:flex-row md:items-center flex-wrap ">
          <div style={{width:"30%",marginLeft:"5%"}} className="">
            <p style={{color:"#2d9dd7"}} className="text-2xl md:text-3xl lg:text-4xl font-bold mb-3">If you want to contact us</p>
            <hr className="w-16 md:w-20 lg:w-24 h-1 bg-secondary-blue border-0 rounded mb-3" />
            <p className="text-sm md:text-base lg:text-lg xl:text-xl pb-4 text-white">
            Send us a brief for your project and we'll respond within 48 hours.</p>

          </div>
          <div style={{ marginLeft: "44.5%",height:"0%" }} className="">
<GetRfiButton onClick={()=>nav("/contact-us")}  fontSize={"30px"} height={"70px"}/>
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default CallCard;

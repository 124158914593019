import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Blog, CaseStudy } from '../types';
import { useParams } from 'react-router-dom';

const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
  `}
`;



const BlogMainResults: React.FC = () => {

    const { tag } = useParams();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const cardsPerPage = 5;

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`plasticum.tn/api/caseStudies/tag/${tag}`);
      setBlogs(response.data);
      console.log('Tag:', tag);
      console.log('Data from API:', response.data);


    } catch (error) {
      console.error('Erreur lors de la récupération des blogs :', error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [tag]);


 // Pagination
const totalPages = Math.ceil(blogs.length / cardsPerPage);
const startIndex = (currentPage - 1) * cardsPerPage;
const endIndex = startIndex + cardsPerPage;
const currentBlogCards = blogs.slice(startIndex, endIndex);

const handlePrevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};

const handleNextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};
const handleTagClick = () => {
    window.scrollTo(0, 0);
  };


  return (

      <div className="mx-20 my-8">
      <div className="flex justify-right mb-8 space-x-20 ">
      <h1>test test</h1>
      {currentBlogCards.map((blogItemData) => (
          <div
            key={blogItemData.id}
            className="bg-bg-blue p-4 rounded-lg shadow-md mb-4 hover:bg-gray-300 cursor-pointer"
          >
            <Link to={blogItemData.link}>
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-4">
                <div className="flex flex-col space-y-2 md:w-2/3">
                  <div className="flex flex-wrap space-x-2">
                  {blogItemData.tags.map((tag, index) => (
                    <Link
                        key={index}
                        to={`/results?tag=${tag}`}
                        className="text-blue-500"
                        onClick={handleTagClick}
                    >
                        #{tag}
                    </Link>
                    ))}
                  </div>
                  <TextContainer>
                    <CardTitle>{blogItemData.title}</CardTitle>
                    <CardHr />
                    <CardText>{blogItemData.text}</CardText>
                    <p className="pt-8 font-bold">
                      {blogItemData.date ? new Date(blogItemData.date).toLocaleDateString() : 'Date invalide'}
                    </p>
                  </TextContainer>
                </div>
                <div className="lg:w-[400px] sm:w-[250px]">
                  <img
                    src={blogItemData.imageUrl}
                    alt={blogItemData.title}
                    className="w-[400px] h-[400px] rounded-lg pb-4 pr-2"
                  />
                </div>
                <p className="absolute font-bold pt-[370px]">Clicks : {blogItemData.numOfClicks}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {blogs.length > cardsPerPage && (
      <div className="flex justify-center mt-4 space-x-4">
        <button
          className="px-3 py-1 text-lg font-bold text-white bg-secondary-blue rounded-lg"
          disabled={currentPage === 1}
          onClick={handlePrevPage}
        >
          Prev
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 text-lg font-bold ${
              currentPage === index + 1 ? 'text-secondary-blue underline underline-offset-8' : 'text-main-blue'
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="px-3 py-1 text-lg font-bold text-white bg-secondary-blue rounded-lg "
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    )}
    </div>
  );
};

export default BlogMainResults;

import React, { useState } from 'react';

import tw from 'twin.macro';
import { MainButtonLeft, MainButtonRight } from '../Buttons';
import { Reveal } from '../Reveal';
import styled, { css } from 'styled-components';
import img1 from "../../assets/images/ITM/MEULE A DISC-1.jpg";
import img2 from "../../assets/images/ITM/EQUIPE MECANIQUE.jpg";
import img3 from "../../assets/images/ITM/CNC-1.jpg";
import img4 from "../../assets/images/ITM/MECANIQUE-6.jpg";
import img5 from "../../assets/images/ITM/ROBOFORM.jpg";
import img6 from "../../assets/images/ITM/MECANIQUE-1.jpg";
import img7 from "../../assets/images/ITM/FOND DIV 1 ITM.jpg";import { useEffect} from 'react';


interface ICardProps {
  color?: "blue" | "white";
  padding?: "left" | "right";
}
const Card = styled.div`
  ${tw`
    w-full
 
    mb-[-380px] 
    
    flex
    flex-col lg:flex-row
    bg-bg-blue
    px-4 lg:px-[11rem]
    py-12
    space-y-4 lg:space-y-0
    lg:space-x-40
    lg:grid-cols-2
  
  `};
  ${({ color }: any) => (color === 'blue' ? tw`bg-bg-blue` : tw`bg-white`)}
  as any
  @media (max-width: 1100px) {
    ${css`
      height: 800px;
      md:bg-transparent bg-gray-100
      visibility: visible
      flex-direction: row;
    `}
  }

`;

const CardImg = styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tl-[170px]
    rounded-br-[120px]
    col-span-2
    relative 
    z-10
  `}
`;

const CardImgR= styled.img`
  ${tw`
    h-[25rem]
    w-[31rem]
    rounded-tr-[170px]
    rounded-bl-[120px]
    col-span-2
    relative 
    z-10
    
  `}
`;

const Container = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
`;

const Container2 = styled.div`
  ${tw`
    flex
    flex-col
    items-start
    col-span-1
    w-full
  `}
  @media (max-width: 1100px) {
    ${css`
    height:550px;
    
    `}
  }
`;
const TextContainer = styled.div`
  ${tw`
    flex
    flex-col
    mt-[20px]
  `}
`;

const CardTitle = styled.p`
  ${tw`
    text-[36px]
    font-bold
    text-main-blue
    justify-start
    text-justify
  `}
`;

const CardHr = styled.hr`
  ${tw`
    w-32
    h-1.5
    mt-[1rem]
    mb-[2rem]
    bg-main-blue
    border-0
    rounded
  `}
`;

const CardText = styled.p`
  ${tw`
    text-[20px]
    text-neutral-600
    dark:text-neutral-200
    text-justify
    mb-[1rem]
  `}
`;
const BlueBackground = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-40
    relative
    overflow-hidden
  `}
  background-color:#FEC244;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground3 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-60
    relative
    overflow-hidden
  `}
  background-color:#616382;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;
const BlueBackground5 = styled.div`
  ${tw`
    rounded-tr-[170px]
    rounded-bl-[130px]
    absolute
    top-[-23rem]  
    right-4
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-60
    relative
    overflow-hidden
  `}
  background-color:#F56401;
  @media (max-width: 1100px) {
    ${css`
    w-[23rem]
    `}

`;

const BlueBackgroundL = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-70
    relative
    overflow-hidden
   
  `}
  background-color:#3D4062;
`;
const BlueBackgroundL4 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-30
    relative
    overflow-hidden
   
  `}
  background-color:#3C1D0F;
`;
const BlueBackgroundL6 = styled.div`
  ${tw`
    rounded-tl-[5rem]
    rounded-br-[130px]
    absolute
    top-[-23rem]  
    left-5
    w-[30rem]
    h-[24rem]
    bg-blue-500
    opacity-70
    relative
    overflow-hidden
   
  `}
  background-color:#4E69DD;
`;
const ImageWrapper = styled.div`
  ${tw`
    w-full
    mb-10
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
      height: 750px;
    
    `}
  }
`;

const ImageWrapper2 = styled.div`
  ${tw`
    w-full
    mb-0
    relative
  `}
  @media (max-width: 1100px) {
    ${css`
    height: 700px;
    
    `}
  }
`;
export function ContentCardT5 (props: ICardProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Experience the Excellence</CardTitle>
            <CardHr />
            <CardText>
            By seamlessly integrating proactive and reactive maintenance strategies into our operations, we ensure that the tools are always operating optimally. Combined with our extensive capabilities, including CNC machining and other advanced technologies, PLASTICUM maximizes tool performance and longevity, enabling us to provide consistent, high-quality injection-molded components to meet our clients' exact specifications and industry demands.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img1} />
  <BlueBackground />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img2} />
        <BlueBackgroundL/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Empowering Plastic Transformation</CardTitle>
            <CardHr />
            <CardText>
            The pursuit of precision is an ongoing journey. We constantly analyze data, gather insights, and make iterative refinements in our processes. These refinements are aimed at enhancing the precision of the tools, minimizing variability, and elevating the overall quality of injection-molded products.            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>

    <div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Sculpting Innovation</CardTitle>
            <CardHr />
            <CardText>
            Precision begins at the machining stage. We employ cutting-edge technologies, including CNC machining, EDM, and high-precision milling. These technologies enable us to craft tooling components with incredibly tight tolerances, achieving the exact specifications required for the injection molding process.
            </CardText>
          
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
        <ImageWrapper className="lg:relative">
          <CardImgR src={img3} />
          <BlueBackground3 />
        </ImageWrapper>
      </div>
    </Card>
  </Reveal>
</div> 

<Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img4} />
        <BlueBackgroundL4/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Crafting Dreams Together</CardTitle>
            <CardHr />
            <CardText>
            At the heart of precision is excellent engineering. Our team of skilled engineers leverages their expertise and experience to meticulously design tooling that aligns with the project's requirements. This includes assessing tolerances, material properties, and anticipated stress points to ensure the tool is designed for longevity and optimal performance.            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>
<div className="lg:flex">
  <Reveal>
    <Card color="blue" className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:mb-0">
        <Container className="mb-8 lg:mb-0">
          <TextContainer>
            <CardTitle>Industry-leading Tools</CardTitle>
            <CardHr />
            <CardText>
            Our capabilities, including CNC machining, EDM (Electrical Discharge Machining), and advanced milling, are vital in tool maintenance and fabrication. CNC machining ensures precise and consistent tooling components. EDM is utilized for complex shapes and intricate details. Additionally, milling helps in tool modification and enhancement, contributing to extended tool life and improved performance.
              
            </CardText>
           
          </TextContainer>
        </Container>
      </div>
      <div className="lg:w-1/2 row-span-1 md:mt-8 flex flex-col justify-between mb-4">
      <ImageWrapper className="lg:relative mb-[-4rem] md:mb-[-8rem]">
  <CardImgR src={img5} />
  <BlueBackground5 />
</ImageWrapper>

      </div>
    </Card>
  </Reveal>
</div> 


     <Reveal>
      <Card color="white">
      <div className='mb-20' style={{ order: isMobile ? '2' : 'initial' }}>
        <ImageWrapper2 >
        <CardImg src={img6} />
        <BlueBackgroundL6/>
        </ImageWrapper2>
        </div> 
        <Container2 style={{ order: isMobile ? '1' : 'initial' }}>
          <TextContainer>
            <CardTitle >Experience the Excellence</CardTitle>
            <CardHr />
            <CardText>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu
            </CardText>
            
           
           
          </TextContainer>
        </Container2>
        
        
      </Card>
    </Reveal>


    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

interface Props {
  children: JSX.Element;
  width?: 'fit-content' | '100%';
}

export const Reveal = ({ children, width = 'fit-content' }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = ref.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        if (rect.top + rect.height >= 0 && rect.bottom - rect.height <= windowHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={ref}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <motion.div
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0, y: 40 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{
          duration: 1.5, 
          ease: [0.6, 0.05, 0.01, 0.9], 
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

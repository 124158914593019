import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import logo from '../../assets/images/logoPT.png'; // Import your logo image here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faL, faMap, faSearch, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import useScroll from '../../states/useScroll';
import { faBars, faTimes, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link, To, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GetRfiButton } from '../Buttons';


const navStyles = css`
  ${tw`
    bg-white
    flex
    justify-between
    items-center
    p-4
    fixed
    top-0
    left-0
    right-0
    z-50
    sticky
    shadow-lg
  `}
`;

const NavContainer = styled(motion.nav) <{ isScrolled: boolean }>`
  ${navStyles};
  transform: translateY(${({ isScrolled }) => (isScrolled ? '-40px' : '0px')});
  z-index: 999;
  will-change: transform;
  transition: transform 0.3s ease; // Specify only transform here
  backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
  background-color: rgba(255, 255, 255, 0.75); /* Adjust the background color and opacity as needed */
  opacity: 1;
`;

const logoStyles = css`
  ${tw`
    h-[100px]
    transition-all
    duration-300
    pl-16
    py-1
  `}
`;

const NavLogo = styled(motion.img) <{ isScrolled: boolean }>`
  ${logoStyles};
  height: ${({ isScrolled }) => (isScrolled ? '60px' : '80px')};
  @media (max-width: 768px) {
    height: ${({ isScrolled }) => (isScrolled ? '60px' : '70px')};
    margin-left: -60px;
  }
  transition: height 0.3s ease; // Specify only height here
`;



const NavMenu = styled(motion.ul)`
  ${tw`
    flex
    list-none
  `}
  margin-right: 60px; // Example static margin-right

`;

const navMenuItemStyles = ({ hasDropdown, isScrolled }: { hasDropdown: boolean; isScrolled: boolean }) => css`
  ${tw`
    flex
    items-center
    pl-6
    text-main-blue
    cursor-pointer
    font-bold
    relative
    pt-6

  `}

`;

const DropdownMenu = styled.div`
  ${tw`
    absolute
    top-full
    left-0
    w-48
    w-[330px]
    bg-white
    shadow-md
    rounded
    hidden
  `}
  flex-direction: column;
  text-align: left;
  z-index: 999;
  transition: height 0.3s ease;
`;

const NavMenuItemContainer = styled(motion.li) <{ hasDropdown: boolean; isScrolled: boolean }>`
  ${({ hasDropdown, isScrolled }) => navMenuItemStyles({ hasDropdown, isScrolled })}
  
  &:hover .dropdown-menu {
    display: block;
    zIndex: 2;
  }
`;

const DropdownItem = styled.div`
  ${tw`
    px-4
    py-2
    text-main-blue
    cursor-pointer
    font-semibold
    transition-colors
    hover:text-secondary-blue
  `}
`;

const buttonStyles = css`
  ${tw`
    justify-between
    pr-4
  `}
`;

const ButtonContainer = styled(motion.div) <{ isScrolled: boolean }>`
  ${buttonStyles};
  padding-top: ${({ isScrolled }) => (isScrolled ? '8px' : '20px')};
  transition: padding-top 0.3s ease;
  
  @media (max-width: 1300px) {
    margin-right: 60px;
    margin-top: 90px;
  }
`;

const rightItmesStyles = css`
  ${tw`
    self-center
    justify-between
    space-x-2
    pr-16
    pl-0
    mt-8
  `}
`;

const RightIems = styled(motion.div) <{ isScrolled: boolean }>`
  ${rightItmesStyles};

  transform: translateY(${({ isScrolled }) => (isScrolled ? '50px' : '-8px')});
  transition: transform 0.3s ease;
`;


interface MenuItemProps {
  title: string;
  items: string[];
  links?: string[];
  collapseOnSmallScreen?: boolean;
  isTitleClickable?: boolean;

}



const NavMenuItem: React.FC<MenuItemProps> = ({
  title,
  items,
  links,
  collapseOnSmallScreen = true,
  isTitleClickable = true,
}) => {
  const isScrolled = useScroll();
  const [dropdownOpen, setDropdownOpen] = useState(!collapseOnSmallScreen);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDropdownToggle = () => {
    if (isSmallScreen) {
      setDropdownOpen(!dropdownOpen);
    }
  };



  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <NavMenuItemContainer hasDropdown={items.length > 0} isScrolled={isScrolled}>
      <div
        onClick={isSmallScreen ? handleDropdownToggle : undefined}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}
      >
        {isTitleClickable && links ? ( // Conditionally render as link or text
          <Link to={links[0]} onClick={scrollToTop}>
            {title}
          </Link>
        ) : (
          <span>{title}</span> // Render as text if isTitleClickable is false
        )}
        {items.length > 0 && (
          <FontAwesomeIcon
            icon={faChevronDown}
            color="main-blue"
            size="xs"
            className="pr-2"
          />
        )}
      </div>
      {isSmallScreen ? (
        dropdownOpen && (
          <div style={{ marginTop: '10px' }}>
            {items.map((item, index) => (
              <DropdownItem key={index}>
                              {links ? (
                <Link to={links[index + 1]} onClick={scrollToTop}>
                  {item}
                </Link>
              ) : (
                item
              )}
              </DropdownItem>
            ))}
          </div>
        )
      ) : (
        <DropdownMenu className="dropdown-menu">
          {items.map((item, index) => (
            <DropdownItem key={index}>
                            {links ? (
                <Link to={links[index + 1]} onClick={scrollToTop}>
                  {item}
                </Link>
              ) : (
                item
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </NavMenuItemContainer>
  );
};


const Nav: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const useScrollToTopNavigate = () => {
    const navigate = useNavigate();

    return (path: To) => {
      navigate(path);
      window.scrollTo(0, 0);
    };
  };
  const navigateToTop = useScrollToTopNavigate(); // Using the custom hook

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | null | undefined = null;

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 50;
      const isNearThreshold = Math.abs(scrollY - threshold) < 10;

      if (isNearThreshold && timeoutId === null) {
        timeoutId = setTimeout(() => {
          setIsScrolled(scrollY > threshold);
          timeoutId = null;
        }, 100); // Delay state update by 100ms
      } else if (!isNearThreshold && timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  /*code responsive*/
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1300);
      closeMenu();
    };


    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.style.backgroundColor = 'white';
    event.currentTarget.style.color = '#2d9dd7';
  };

  const handleMouseOut = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.style.backgroundColor = '#2d9dd7';
    event.currentTarget.style.color = 'white';
  };
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <NavContainer
      initial={{ y: 0 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 200 }}
      isScrolled={isScrolled}
    >
      <Link to="/">
        <NavLogo
          src={logo}
          alt="Logo"
          isScrolled={isScrolled}
          className={isSmallScreen ? 'small-logo' : ''}
        />
      </Link>
      {isSmallScreen && (
        <button onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      )}

      <NavMenu className={`nav-menu ${isMenuOpen ? 'show-menu' : ''} ${isSmallScreen ? 'vertical-menu' : 'right'}`}>
        {isSmallScreen && (
          <button onClick={toggleMenu} className="close-menu-button">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        )}
        <NavMenuItem title="Turnkey Solution" isTitleClickable={false} // Set to false for non-clickable title
          items={["Co-design & Product Development", "Tooling Development & Manufacturing", "Plastic Injection Molding", "Injection-related Services", "Integrated Tool Management"]} links={["/turnkey-solutions", "/co-design-product-development", "/tooling-development-manufacturing", "/plastic-injection-molding", "/injection-related-services", "/integrated-tool-management"]} collapseOnSmallScreen={true} />
        <NavMenuItem title="Industries" isTitleClickable={false} // Set to false for non-clickable title
          items={["Automotive", "Domotic", "Other Industries"]} links={["/industries", "/automotive", "/domotic", "/other-industries"]} collapseOnSmallScreen={true} />

        <NavMenuItem title="Careers" items={[]} links={['/careers']} collapseOnSmallScreen={true} />

        <NavMenuItem title="Company" items={[]} links={['/company']} collapseOnSmallScreen={true} />
        <NavMenuItemContainer hasDropdown={false} isScrolled={isScrolled}>
          <GetRfiButton onClick={() => navigateToTop("/contact-us")}></GetRfiButton>
        </NavMenuItemContainer>


        <NavMenuItemContainer hasDropdown={false} isScrolled={isScrolled}>
          <a href="https://www.linkedin.com/company/plasticumtunisie/" target="_blank" className="ml-4">
            <FontAwesomeIcon icon={faLinkedinIn} size="lg" className="fa-border text-main-blue" />
          </a>
        </NavMenuItemContainer>



      </NavMenu>
    </NavContainer>
  );
};


export default Nav;